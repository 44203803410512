import * as React from "react"
import { ViewIdentifier, ViewType } from "@digits-graphql/frontend/graphql-bearer"
import { SvgDownloadCloud01Solid } from "@digits-shared/components/SVGIcons/solid/DownloadCloud01Solid.svg"
import objectHelper from "@digits-shared/helpers/objectHelper"
import useSession from "@digits-shared/hooks/useSession"
import Session from "@digits-shared/session/Session"
import { useChromeAlertContext } from "src/frontend/components/Shared/Alerts/ChromeAlertsContext"
import { useViewType } from "src/frontend/components/Shared/Contexts/ViewTypeContext"
import { ViewVersionContext } from "src/frontend/components/Shared/Contexts/ViewVersionContextConst"
import { useInternalUserSettings } from "src/frontend/hooks/useInternalUserSettings"
import FrontendSession from "src/frontend/session"

export type ViewVersion = ViewIdentifier

export interface ViewVersions {
  accounting: Map<string, Map<ViewType, ViewVersion>>
  updateKey: string
  appAreaChange: number
  forceUpdate: () => void
}

interface UseViewVersionOptions {
  legalEntityId?: string
  viewTypeOverride?: ViewType
}

/**
 * Returns the View Version for ViewType.
 */
export function useViewVersion(options?: UseViewVersionOptions): ViewVersion {
  const { viewTypeOverride, legalEntityId } = options || {}
  const viewType = useViewType(viewTypeOverride)
  const { accounting, appAreaChange, updateKey, forceUpdate } = React.useContext(ViewVersionContext)
  const session = useSession<FrontendSession>()
  const { newViewVersionAlertEnabled } = useInternalUserSettings()
  const { addAlert, removeAlert } = useChromeAlertContext()
  const targetLegalEntityId = legalEntityId || session.currentLegalEntityId

  const findViewVersion = React.useCallback(() => {
    const legalEntityViews = accounting.get(targetLegalEntityId)
    return (
      legalEntityViews?.get(viewType) ?? {
        viewVersion: "",
        mutationVersion: "",
        viewType,
        legalEntityId: targetLegalEntityId ?? "",
      }
    )
  }, [accounting, targetLegalEntityId, viewType])

  const activeViewVersion = React.useRef<ViewVersion>(findViewVersion())
  const latestViewVersion = activeViewVersion.current

  React.useEffect(() => {
    session.on(Session.MUTATION_VERSION_NAMESPACE, forceUpdate)

    return () => {
      session.off(Session.MUTATION_VERSION_NAMESPACE, forceUpdate)
    }
  }, [forceUpdate, session])

  // The view version should be updated whenever forced (the background task triggers, or the alert is clicked),
  // the user changes application modules, or the current legal entity changes.
  // The values [updateKey, appAreaChange] aren't needed for this new ViewVersion value,
  // but gate the updating of the value via the deps array.
  React.useEffect(() => {
    const newVersion = findViewVersion()
    if (!objectHelper.deepEqual(activeViewVersion.current, newVersion)) {
      forceUpdate()
    }
    activeViewVersion.current = newVersion
  }, [updateKey, appAreaChange, findViewVersion, forceUpdate])

  React.useEffect(() => {
    // If opted in via employee setting, show an alert when a new view version is available for the current LE.
    if (newViewVersionAlertEnabled) {
      if (
        !!activeViewVersion.current &&
        !objectHelper.deepEqual(activeViewVersion.current, latestViewVersion)
      ) {
        addAlert({
          id: "viewVersionChange",
          message: "Updated data is available.",
          description: "Please save any pending edits before refreshing.",
          autoDismiss: false,
          buttonText: "Refresh",
          SVGComponent: SvgDownloadCloud01Solid,
          type: "info",
          onClick: forceUpdate,
        })
      } else {
        removeAlert("viewVersionChange")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addAlert,
    removeAlert,
    forceUpdate,
    session.isDigitsEmployee,
    latestViewVersion.viewVersion,
    latestViewVersion.viewType,
    latestViewVersion.legalEntityId,
    newViewVersionAlertEnabled,
  ])

  return latestViewVersion
}
