import * as React from "react"
import { Link } from "react-router-dom"
import { DocumentStatus, Interval } from "@digits-graphql/frontend/graphql-bearer"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { FilterCheckbox } from "@digits-shared/DesignSystem/FormElements/FilterCheckbox"
import { DigitsTooltip } from "@digits-shared/DesignSystem/Tooltip"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import useLazyTimeout from "@digits-shared/hooks/useLazyTimeout"
import colors from "@digits-shared/themes/colors"
import styled, { css, keyframes } from "styled-components"
import { VaultFile } from "src/frontend/components/OS/Springboard/Applications/Vault/types"
import { FileActionsMenu } from "src/frontend/components/OS/Springboard/Applications/Vault/VaultBrowser/FileActionsMenu"
import { useFilePreview } from "src/frontend/components/OS/Springboard/Applications/Vault/VaultBrowser/useFilePreview"
import { FileSelection } from "src/frontend/components/OS/Springboard/Applications/Vault/VaultBrowser/useVaultFileSelection"
import { fileTypeFor } from "src/frontend/components/OS/Springboard/Applications/Vault/vaultMime"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import { useTextIsOverflowing } from "src/frontend/hooks/useTextIsOverflowing"
import routes from "src/frontend/routes"
import { FileSvgIcon } from "src/shared/components/Icons/FileIcon"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

/*
  STYLES
*/

const CheckboxContainer = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 10px;
  left: 10px;

  transition: opacity 250ms;
  pointer-events: auto;

  ${({ visible }) =>
    !visible &&
    css`
      opacity: 0;
    `}
  input:not(:checked) {
  }
`

const FileSquare = styled(Link)<{ $processing: boolean; disabled?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${colors.translucentWhite60};
  border-radius: 8px;
  width: 245px;

  ${({ $processing }) =>
    $processing &&
    css`
      background: ${colors.white};
      border: 2px solid rgba(50, 73, 127, 0.2);
      box-shadow: 0 0 24px 0 rgba(125, 125, 205, 0.39);
    `}
  &:hover {
    box-shadow: 0 0 24px 0 rgba(125, 125, 205, 0.39);

    ${CheckboxContainer} {
      opacity: 1;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`

const IMG_STYLES = css<{ $processing: boolean }>`
  width: 100%;
  height: 184px;
  object-fit: cover;
  object-position: top;
  border-radius: 6px;

  border: 2px solid ${colors.white};
  ${({ $processing }) =>
    $processing &&
    css`
      border-color: transparent;
    `}
`

const Cover = styled.img<{ $processing: boolean }>`
  ${IMG_STYLES};
`

const Placeholder = styled.div<{ $processing: boolean }>`
  ${IMG_STYLES};
  background: rgba(50, 73, 127, 0.2);
  ${({ $processing }) =>
    $processing &&
    css`
      background: ${colors.white};
    `}

  display: flex;
  align-items: center;
  justify-content: center;
`

const PlaceholderIcon = styled(FileSvgIcon)<{ $processing: boolean }>`
  width: 53px;
  height: 60px;
  ${({ $processing }) =>
    $processing &&
    css`
      filter: grayscale(1);
    `}
`

const MetaContainer = styled.div<{ $processing: boolean }>`
  flex: 1;
  display: flex;
  gap: 8px;
  padding: 8px;
  border-radius: 0 0 8px 8px;
  position: relative;

  ${({ $processing }) =>
    $processing &&
    css`
      background: ${colors.white};
    `}
`

const StyledIcon = styled(FileSvgIcon)`
  width: 28px;
  height: 32px;
  flex-shrink: 0;
`

const Meta = styled.div`
  overflow: hidden;
`

const Name = styled.div`
  font-size: 14px;
  overflow: hidden;
  max-width: 192px;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Date = styled.div`
  font-size: 12px;
  color: ${colors.secondary70};
`

const Indicator = styled.div<{ completed?: boolean }>`
  position: absolute;
  left: -2px;
  bottom: -2px;
  width: calc(100% + 4px);
  background-color: rgba(117, 151, 232, 0.2);
  border-radius: 0 0 7px 7px;
  border: 1px solid ${colors.transparent};

  height: 0;
  transition:
    height 200ms,
    background-color 200ms 400ms,
    border-color 200ms 400ms;

  ${({ completed }) =>
    completed &&
    css`
      border-radius: 7px;
      border-color: ${colors.primary};
      background-color: rgba(77, 193, 186, 0.4);
    `};
`

const WrappingTitle = styled.div`
  line-break: anywhere;
`

/*
  INTERFACES
*/
interface FileProps {
  file: VaultFile
  selection: FileSelection | undefined
  className?: string
}

interface FileStatusProps {
  file: VaultFile
  processing: boolean
  imageUrl: string | undefined
}

/*
  COMPONENTS
*/
export const VaultFileItem: React.FC<FileProps> = ({ file, selection, className }) => {
  const pathGenerator = useFrontendPathGenerator()
  const disabled = file.status === DocumentStatus.New || !!selection?.selectedIds.size
  const stopPropagation = useStopClickPropagation()

  const imageUrl = useFilePreview(file)
  const processing = file.status !== DocumentStatus.Done

  return (
    <FileSquare
      className={className}
      $processing={processing}
      disabled={disabled}
      onClick={stopPropagation}
      to={pathGenerator(routes.vaultFile, { fileId: file.id })}
    >
      {selection && <FileCheckBox file={file} selection={selection} />}
      <FilePreview file={file} processing={processing} imageUrl={imageUrl} />
      <FileMeta file={file} processing={processing} imageUrl={imageUrl} />
      <FileActionsMenu file={file} selection={selection} />
      <ProgressIndicator file={file} processing={processing} imageUrl={imageUrl} />
    </FileSquare>
  )
}

export const LoadingVaultFile: React.FC = () => (
  <FileSquare to="" $processing={false} css="display: block">
    <LoadingBlock width="100%" height="184px" />
    <LoadingBlock width="100%" height="20px" />
    <LoadingBlock width="100%" height="22px" />
  </FileSquare>
)

const FileCheckBox: React.FC<{ file: VaultFile; selection: FileSelection }> = ({
  file: { id },
  selection: { selectedIds, select, unselect },
}) => {
  const onCheckChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        select(id)
      } else {
        unselect(id)
      }
    },
    [id, select, unselect]
  )

  return (
    <CheckboxContainer visible={!!selectedIds.size}>
      <FilterCheckbox checked={selectedIds.has(id)} onChange={onCheckChange} />
    </CheckboxContainer>
  )
}

const FilePreview: React.FC<FileStatusProps> = ({ file, processing, imageUrl }) => {
  if (!imageUrl)
    return (
      <Placeholder $processing={processing}>
        <PlaceholderIcon $processing={processing} fileType={fileTypeFor(file)} />
      </Placeholder>
    )

  return <Cover $processing={processing} src={imageUrl} />
}

const FileMeta: React.FC<FileStatusProps> = ({ file, processing }) => {
  const { textElementRef, isTextOverflowing } = useTextIsOverflowing<HTMLDivElement>()
  const title = file.title ?? file.fileName ?? "Untitled"

  return (
    <MetaContainer $processing={processing}>
      <StyledIcon fileType={fileTypeFor(file)} />
      <Meta>
        <DigitsTooltip
          content={<WrappingTitle>{title}</WrappingTitle>}
          disabled={!isTextOverflowing}
        >
          <Name ref={textElementRef}>{title}</Name>
        </DigitsTooltip>

        <Date>
          Uploaded {dateTimeHelper.displayNameFromUnixTimestamp(file.createdAt, Interval.Day)}
        </Date>
      </Meta>
    </MetaContainer>
  )
}

const ProgressIndicator: React.FC<FileStatusProps> = ({ file, processing, imageUrl }) => {
  const [newFile, setNewFile] = React.useState(processing)

  const processingDone = file.status === DocumentStatus.Done || !!imageUrl

  const [complete] = useLazyTimeout(() => setNewFile(false), 2000)
  React.useEffect(() => {
    if (processingDone) {
      complete()
    }
  }, [complete, file.status, processingDone])

  if (!newFile) return null

  let progress = css`
    height: calc(${100}% + 4px);
  `

  if (!processingDone) {
    progress = css`
      height: 88%;
      animation: ${keyframes` from {
                                height: 88%;
                              }
                                to {
                                  height: 96%;
                                }`} 3s ease-in-out forwards;
    `
  }

  if (file.status === DocumentStatus.New) {
    progress = css`
      height: 45%;
      animation: ${keyframes` from {
                                height: 45%;
                              }
                                to {
                                  height: 88%;
                                }`} 3s ease-in-out forwards;
    `
  }

  if (file.status === DocumentStatus.New && !file.creator) {
    progress = css`
      height: 0;
      animation: ${keyframes` from {
                                height: 0;
                              }
                                to {
                                  height: 45%;
                                }`} 2.5s ease-in-out forwards;
    `
  }

  return <Indicator completed={processingDone} css={progress} />
}
