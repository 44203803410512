import * as React from "react"
import { Link } from "react-router-dom"
import { usePopOverState } from "@digits-shared/hooks/usePopOverState"
import styled, { createGlobalStyle } from "styled-components"
import { HoverableEntity } from "src/shared/components/ObjectEntities/EntitiesParser"
import { EntityPopOverComponent } from "src/shared/components/ObjectEntities/entityPopOverTypes"
import { useEntityLink } from "src/shared/components/ObjectEntities/useEntityLink"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

/*
  STYLES
*/

const PopOverContainer = styled.span`
  position: relative;
  pointer-events: auto;
`

const ChildWrapper = styled.span`
  display: contents;
  white-space: normal;
  cursor: pointer;
`

const DisableContentClicks = createGlobalStyle`
  *[contenteditable="true"] {
    pointer-events: none;
  }  
`

/*
  INTERFACES
*/

interface PopOverProps {
  entity: HoverableEntity
  entityPopOver?: EntityPopOverComponent
  disableHover?: boolean
  className?: string
  children?: React.ReactNode
}

/*
  COMPONENTS
*/

export const EntityPopOver: React.FC<PopOverProps> = ({
  entity,
  entityPopOver,
  className,
  children,
  disableHover,
}) => {
  const { isPopOverOpen, onMouseEnter: onPopOverEnter, onMouseLeave } = usePopOverState()
  const { Component, onHover } = entityPopOver || { Component: null, onHover: undefined }
  const path = useEntityLink(entity)
  const stopPropagation = useStopClickPropagation()

  const onMouseEnter = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      // Do not trigger the popover if there is text selected
      const selection = window.getSelection()
      const textSelected = selection?.rangeCount && !selection?.getRangeAt(0)?.collapsed
      if (textSelected) {
        return
      }
      return onPopOverEnter(e)
    },
    [onPopOverEnter]
  )

  React.useEffect(() => {
    onHover?.(isPopOverOpen)
  }, [onHover, isPopOverOpen])

  if (disableHover || !path) {
    return <PopOverContainer className={className}>{children}</PopOverContainer>
  }

  if (!Component) {
    return (
      <Link to={path} onClick={stopPropagation}>
        <PopOverContainer className={className}>{children}</PopOverContainer>
      </Link>
    )
  }

  return (
    <PopOverContainer className={className} onMouseLeave={onMouseLeave}>
      {isPopOverOpen && (
        <>
          <Component entity={entity} options={undefined} />
          <DisableContentClicks />
        </>
      )}
      <ChildWrapper onMouseEnter={onMouseEnter}>{children}</ChildWrapper>
    </PopOverContainer>
  )
}
