import * as React from "react"
import { Route, Switch } from "react-router-dom"
import { DigitsRoute } from "@digits-shared/components/Router/DigitsRoute"
import { LoggedRedirect } from "@digits-shared/components/Router/LoggedRedirect"
import { ProtectedRoute } from "@digits-shared/components/Router/ProtectedRoute"
import { useCurrentRoute } from "@digits-shared/hooks/useCurrentRoute"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import { Home } from "src/frontend/components/OS/Home/Home"
import AccountantApplications from "src/frontend/components/OS/Springboard/Accountant/AccountantApplications"
import { useSearchKeyLauncher } from "src/frontend/components/OS/Springboard/Applications/Search/useSearchKeyLauncher"
import OperatorApplications from "src/frontend/components/OS/Springboard/Operator/OperatorApplications"
import { COMMON_APPLICATION_PARAMETERIZE_PATHS } from "src/frontend/components/OS/Springboard/useLegalEntityRedirects"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import FrontendSession from "src/frontend/session"
import { Experience } from "src/frontend/session/personas"
import NotFound from "src/shared/components/Elements/PageNotFound"

const ConnectorAuthenticationApplication = React.lazy(
  () =>
    import(
      "src/frontend/components/OS/Springboard/Applications/Connections/Connector/ConnectorAuthenticationApplication"
    )
)

const LinkApplication = React.lazy(
  () => import("src/frontend/components/OS/Springboard/Applications/Link/LinkApplication")
)

export const OSContent: React.FC = () => {
  const session = useSession<FrontendSession>()
  const currentOrgSlug = session.currentOrganization?.slug
  const generatePath = useFrontendPathGenerator()
  const { location } = useRouter()

  return (
    <Switch location={location}>
      {/* LE-Level Routing */}
      <Route
        path={[routes.legalEntity.parameterizedPath, routes.search.parameterizedPath]}
        render={() => (
          <ProtectedRoute redirectTo={generatePath(routes.login)} component={HomeExperience} />
        )}
      />

      {/* Shared Applications */}
      <Route
        path={COMMON_APPLICATION_PARAMETERIZE_PATHS}
        render={() => (
          <ProtectedRoute redirectTo={generatePath(routes.login)} component={HomeExperience} />
        )}
      />

      {/* Org-Level Routing */}
      <Route
        path={routes.organization.parameterizedPath}
        render={() => (
          <ProtectedRoute redirectTo={generatePath(routes.login)} component={HomeExperience} />
        )}
      />

      <Route
        exact
        path={[routes.dashboard.parameterizedPath, routes.root.parameterizedPath]}
        render={() => (
          <ProtectedRoute redirectTo={generatePath(routes.login)} component={HomeExperience} />
        )}
      />

      {/* Assistant Redirect will send user to isolated assistant experience for current LE */}
      <Route
        path={[
          routes.assistantRedirect.parameterizedPath,
          routes.reportsRedirect.parameterizedPath,
        ]}
        render={() => (
          <ProtectedRoute
            digitsEmployeeOnly
            redirectTo={generatePath(routes.login)}
            component={CurrentLERedirect}
          />
        )}
      />

      {/* Assistant Start will send user to current LE home and auto open assistant */}
      <Route
        path={routes.assistantStart.parameterizedPath}
        render={() => (
          <ProtectedRoute
            digitsEmployeeOnly
            redirectTo={generatePath(routes.login)}
            component={AssistantStart}
          />
        )}
      />

      {/* Root /billing */}
      <Route
        path="/billing"
        render={() => (
          <ProtectedRoute redirectTo={generatePath(routes.login)}>
            <LoggedRedirect
              name="OSContent-rootBilling"
              to={
                currentOrgSlug
                  ? routes.organizationBilling.generate({ orgSlug: currentOrgSlug })
                  : routes.root.generateFromCurrentPath()
              }
            />
          </ProtectedRoute>
        )}
      />

      {/* Connector Routing */}
      <Route
        path={routes.connectorOauth.parameterizedPath}
        component={ConnectorAuthenticationApplication}
      />

      {/* Link Routing */}
      <Route path={routes.link.parameterizedPath} component={LinkApplication} />

      <Route path="*" component={NotFound} />
    </Switch>
  )
}

/**
 * Renders the home view that is at the root of our application hieararchy.
 * For affiliates/accountants, this is the accountant portal/springboard.
 * For clients/operators, this will be the Client Portal.
 */
const HomeExperience: React.FC = () => {
  const { location } = useRouter()

  const { currentLegalEntity, currentPrimaryExperience } = useSession<FrontendSession>()
  const generatePath = useFrontendPathGenerator()
  const { isDeprecatedAccountingRoute, to } = useRequiresDeprecatedAccountingRouteRedirect()
  useSearchKeyLauncher()

  if (location.name === routes.legalEntity.name) {
    return (
      <LoggedRedirect
        name="OSContent-legalEntityHome-homeExperience"
        to={generatePath(routes.legalEntityHome, { leSlug: currentLegalEntity?.slug })}
      />
    )
  }

  if (isDeprecatedAccountingRoute && to) {
    return <LoggedRedirect name="replaceLegacyAccountingRoute" to={to} />
  }

  // Accountant Springboard
  if (currentPrimaryExperience === Experience.AccountantPortal) {
    return (
      <Home>
        <AccountantApplications />
      </Home>
    )
  }

  return (
    <Home>
      <OperatorApplications />
    </Home>
  )
}

/**
 * Redirects users to a default legal entity.
 */
const CurrentLERedirect: React.FC = () => {
  const { location } = useRouter()
  const { currentLegalEntity } = useSession<FrontendSession>()
  const generatePath = useFrontendPathGenerator()

  const { name, route } = React.useMemo(() => {
    switch (location.name) {
      case routes.assistantRedirect.name:
        return { name: "assistantRedirect", route: routes.assistant }
      case routes.reportsRedirect.name:
        return { name: "reportsRedirect", route: routes.reports }
      default:
        return { name: "legalEntityRedirect", route: routes.legalEntity }
    }
  }, [location.name])

  if (currentLegalEntity?.slug) {
    return (
      <LoggedRedirect
        name={`OSContent-${name}-legalEntityFound`}
        to={generatePath(route, { leSlug: currentLegalEntity.slug })}
      />
    )
  }

  return (
    <LoggedRedirect name={`OSContent-${name}-legalEntityNotFound`} to={generatePath(routes.root)} />
  )
}

/**
 * Redirects users to a default legal entity.
 */
const AssistantStart: React.FC = () => {
  const { currentLegalEntity } = useSession<FrontendSession>()
  const generatePath = useFrontendPathGenerator()

  if (currentLegalEntity?.slug) {
    return (
      <LoggedRedirect
        name="OSContent-assistantStart-legalEntityFound"
        to={generatePath(routes.legalEntityHome, {
          leSlug: currentLegalEntity.slug,
          assistant: "start",
        })}
      />
    )
  }

  return (
    <LoggedRedirect
      name="OSContent-assistantStart-legalEntityNotFound"
      to={generatePath(routes.root)}
    />
  )
}

function useRequiresDeprecatedAccountingRouteRedirect() {
  const { location } = useRouter()
  const currentRoute = useCurrentRoute(routes)
  const parameters = currentRoute.getParametersFromPath(location.pathname)
  const checkRoute = (route: DigitsRoute) => route.isRouteOrChildOfRoute(currentRoute)
  const generateTo = (route: DigitsRoute) => route.generate(parameters)

  switch (true) {
    case checkRoute(routes.accountingTransactionDashboardDeprecated):
      return {
        isDeprecatedAccountingRoute: true,
        to: generateTo(routes.accounting),
      }
    case checkRoute(routes.accountingDeprecated):
      return {
        isDeprecatedAccountingRoute: true,
        to: generateTo(routes.accounting),
      }
    default:
      return {
        isDeprecatedAccountingRoute: false,
        to: undefined,
      }
  }
}
