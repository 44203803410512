import { ViewKey } from "@digits-graphql/frontend/graphql-bearer"
import { usePropagateHookToAtom } from "@digits-shared/atoms/usePropagateHookToAtom"
import { createScope } from "bunshi"
import { ScopeProvider } from "bunshi/react"
import { atom, PrimitiveAtom, useSetAtom } from "jotai"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"

// Use the atom when you want dependent atoms/molecules to handle (react to) view key changes internally
export const viewKeyAtom = atom<ViewKey>(null as unknown as ViewKey)

// Use scope when you want all dependent molecules to reset their state when the view key changes
export const ViewKeyScope = createScope<PrimitiveAtom<ViewKey> | undefined>(undefined, {
  debugLabel: "ViewKey",
})

export const ViewKeyScopeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  usePropagateHookToAtom(useSetAtom(viewKeyAtom), useViewVersion)

  return (
    <ScopeProvider scope={ViewKeyScope} value={viewKeyAtom}>
      {children}
    </ScopeProvider>
  )
}
