import {
  EntityCategory,
  EntityDepartment,
  EntityLegalEntity,
  EntityParty,
  EntityReportPackage,
  EntityTransaction,
  ObjectGrant,
  PartyRole,
  ViewType,
} from "@digits-graphql/frontend/graphql-bearer"

export const LEGAL_ENTITY_CONTAINER_HORIZONTAL_PADDING = 8

export const ALL_BUSINESSES: EntityLegalEntity = {
  id: "1",
  name: "All Businesses",
  slug: "all",
}

export interface SharedEntity<E> {
  entity: E
  grantCreatedAt: number
  viewType: ViewType | null | undefined // Not all entity kinds will have a view type
}

export type ShareableEntity =
  | EntityReportPackage
  | EntityTransaction
  | EntityCategory
  | EntityParty
  | EntityDepartment

export interface LegalEntityWithObjectGrant {
  legalEntity: EntityLegalEntity
  grant: ObjectGrant
}

export interface LegalEntitySharedEntities<E> {
  entities: SharedEntity<E>[]
  legalEntity: EntityLegalEntity
}

export interface SharedEntities {
  reportPackages: LegalEntitySharedEntities<EntityReportPackage>[]
  categories: LegalEntitySharedEntities<EntityCategory>[]
  transactions: LegalEntitySharedEntities<EntityTransaction>[]
  departments: LegalEntitySharedEntities<EntityDepartment>[]
  parties: Map<PartyRole, LegalEntitySharedEntities<EntityParty>[]>
}
