import { Interval, ObjectKind } from "@digits-graphql/frontend/graphql-bearer"
import {
  createRoutes,
  DetailsViewAction,
  RouteConfig,
  SharedObjectInfo,
  StaticRoutes,
} from "@digits-shared/components/Router/DigitsRoute"
import { RouteDefaultIntervalOrigin } from "@digits-shared/helpers/dateTimeHelper"
import { isMobile } from "@digits-shared/helpers/devicesHelper"
import {
  FrontendPartyRole,
  SUPPORTED_PARTY_ROLES_URL_KEYS,
} from "src/frontend/types/FrontendPartyRole"

export class FrontendStaticRoutes extends StaticRoutes {
  root = "/"

  // Session
  login = "/login"
  logout = "/logout"
  link = "/link"
  singleSignOn: RouteConfig = {
    parameterizedPath: "/community",
    parentRoute: this.login,
  }

  signupCommunity: RouteConfig = {
    parameterizedPath: "/signup/community",
  }

  // Password
  passwordReset = "/password-reset"

  // Signup
  welcome = "/welcome"
  onboardApplicationStatus = "/onboard/application-status"
  onboardQuickbooksCallback = "/onboard/link-ledger" // it wont render anything (needs to match QBO, dont change)
  plg: RouteConfig = {
    parameterizedPath: "/join",
    moduleName: "Analytics Toolbox",
  }

  // LEGACY Signup / Onboard
  legacySignup = "/signup"
  onboard = "/onboard"

  internalFeatures = {
    parameterizedPath: "/internal-features",
    moduleName: "Employee Features",
  }
  internalDGRedirect = {
    parameterizedPath: "/support/dg", // keeping old path because of go-services ReferrerLinkFormat
    moduleName: "Referrer Link",
  }

  // Legal
  legal = "/legal"
  termsOfService = `${this.legal}/terms-of-service`
  privacyPolicy = `${this.legal}/privacy-policy`
  taxPartnerAgreement = `${this.legal}/tax-partner-agreement`

  dashboard = "/dashboard"

  sharedWithMe = {
    parameterizedPath: "/shared-with-me",
    moduleName: "Shared With Me",
  }

  sharedWithMeReportPackages = {
    parameterizedPath: "/reports",
    parentRoute: this.sharedWithMe,
  }

  sharedWithMeCategories = {
    parameterizedPath: "/categories",
    parentRoute: this.sharedWithMe,
  }

  sharedWithMeDepartments = {
    parameterizedPath: "/departments",
    parentRoute: this.sharedWithMe,
  }

  sharedWithMeTransactions = {
    parameterizedPath: "/transactions",
    parentRoute: this.sharedWithMe,
  }

  sharedWithMeParties = {
    parameterizedPath: `/:partyRole(${SUPPORTED_PARTY_ROLES_URL_KEYS.join("|")})`,
    parentRoute: this.sharedWithMe,
  }

  // Accountant portal
  organization: RouteConfig = {
    parameterizedPath: "/portal/:orgSlug",
    moduleName: "Accountant Portal",
  }

  // LegalEntity-level routes (LE Home Dashboard)
  legalEntity: RouteConfig = {
    parameterizedPath: "/@:leSlug",
    moduleName: "Dashboard",
  }

  legalEntityHome: RouteConfig = {
    parameterizedPath: "/home",
    parentRoute: this.legalEntity,
    moduleName: "Home",
    timeParameterOptions: {
      interval: Interval.Month,
      defaultIntervalOrigin: RouteDefaultIntervalOrigin.Today,
    },
  }

  legalEntityHomeEdit: RouteConfig = {
    parameterizedPath: "/edit",
    parentRoute: this.legalEntityHome,
    moduleName: "Customize",
    detailsViewAction: DetailsViewAction.Push,
  }

  legalEntityHomeLists: RouteConfig = {
    parameterizedPath: "/lists",
    parentRoute: this.legalEntityHomeEdit,
  }

  legalEntityHomeMetrics: RouteConfig = {
    parameterizedPath: "/metrics",
    parentRoute: this.legalEntityHomeEdit,
  }

  legalEntityHomeStatements: RouteConfig = {
    parameterizedPath: "/statements",
    parentRoute: this.legalEntityHomeEdit,
  }

  legalEntityHomeCustom: RouteConfig = {
    parameterizedPath: "/custom",
    parentRoute: this.legalEntityHomeEdit,
  }

  legalEntityHomeAssistant: RouteConfig = {
    parameterizedPath: "/assistant",
    parentRoute: this.legalEntityHomeEdit,
    moduleName: "Assistant",
  }

  // Route for assistant which will redirect to the current
  // legal entity as a centered assistant experience (for mobile).
  assistantRedirect: RouteConfig = {
    parameterizedPath: "/assistant",
    moduleName: "Assistant Redirect",
  }

  // Used for marketing campaigns linking which can be agnostic of a user's legal entity.
  // Will redirect to the current legal entity and auto open the assistant.
  assistantStart: RouteConfig = {
    parameterizedPath: "/assistant-start",
    moduleName: "Assistant Start",
  }

  // A centered assistant experience (for mobile).
  assistant: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/assistant",
    moduleName: "Assistant",
  }

  // Transaction Details Permalink
  // Since a transaction can show up in more than one product area, if attempting to route to a
  // details view without a product area, we will use the default one assigned to that transaction.
  // Prefer using the product area specific transaction details url if the product are is known.
  defaultTransactionDetails: RouteConfig = {
    parameterizedPath: "/transaction/:transactionId",
    parentRoute: this.legalEntity,
    detailsViewAction: DetailsViewAction.Push,
    getSharedObjectInfo: transactionSharedObjectInfo,
  }

  transactionDetails: RouteConfig = {
    parameterizedPath: "/transaction/:transactionId",
    parentRoute: this.legalEntity,
    detailsViewAction: DetailsViewAction.Push,
    moduleName: "Transaction Details",
    getSharedObjectInfo: transactionSharedObjectInfo,
  }

  // Used only for requesting comment context and redirecting to the correct role associated with the comment context
  partyDetailsRedirectForComment: RouteConfig = {
    parameterizedPath: "/party/:partyId/comment/:commentId",
    parentRoute: this.legalEntity,
    detailsViewAction: DetailsViewAction.Push,
    getSharedObjectInfo: partySharedObjectInfo,
  }

  partyDetailsRedirectForThread: RouteConfig = {
    parameterizedPath: "/party/:partyId/thread/:threadId",
    parentRoute: this.legalEntity,
    detailsViewAction: DetailsViewAction.Push,
    getSharedObjectInfo: partySharedObjectInfo,
  }

  partyDetails: RouteConfig = {
    parameterizedPath: `/:partyRole(${SUPPORTED_PARTY_ROLES_URL_KEYS.join("|")})/:partyId`,
    parentRoute: this.legalEntity,
    detailsViewAction: DetailsViewAction.Push,
    timeParameterOptions: {
      interval: Interval.Month,
      defaultIntervalOrigin: RouteDefaultIntervalOrigin.CurrentMonth,
    },
    moduleName: ({ partyRole }) =>
      `${FrontendPartyRole.findByURLKey(partyRole).displayName} Details`,
    getSharedObjectInfo: partySharedObjectInfo,
  }

  partyDetailsCategories: RouteConfig = {
    parameterizedPath: "/categories",
    parentRoute: this.partyDetails,
  }

  partyDetailsTransactions: RouteConfig = {
    parameterizedPath: "/transactions",
    parentRoute: this.partyDetails,
  }

  // Search
  search: RouteConfig = {
    parameterizedPath: `${this.legalEntity.parameterizedPath}/search`,
    moduleName: "Search",
    detailsViewAction: DetailsViewAction.Push,
  }

  // Analysis
  analysis: RouteConfig = {
    parameterizedPath: "/analysis",
    parentRoute: this.legalEntity,
    moduleName: "Analysis",
    timeParameterOptions: {
      interval: Interval.Month,
    },
  }

  // Burn (legacy)
  burn: RouteConfig = {
    parameterizedPath: `${this.legalEntity.parameterizedPath}/burn`,
    timeParameterOptions: {
      interval: Interval.Month,
    },
    moduleName: "Analysis",
  }

  // Expenses (formerly known as Burn)
  expenses: RouteConfig = {
    parentRoute: this.analysis,
    parameterizedPath: "/expenses",
    moduleName: "Analysis",
  }

  expenseTransactions: RouteConfig = {
    parameterizedPath: "/transactions",
    parentRoute: this.expenses,
  }

  expensesByCategories: RouteConfig = {
    parameterizedPath: "/categories",
    parentRoute: this.expenses,
  }

  expensesByCategoriesWaterfall: RouteConfig = {
    parameterizedPath: "/waterfall",
    parentRoute: this.expensesByCategories,
  }

  expensesByVendors: RouteConfig = {
    parameterizedPath: "/vendors",
    parentRoute: this.expenses,
  }

  expensesByVendorsWaterfall: RouteConfig = {
    parameterizedPath: "/waterfall",
    parentRoute: this.expensesByVendors,
  }

  // Revenue
  revenue: RouteConfig = {
    parentRoute: this.analysis,
    parameterizedPath: "/revenue",
    moduleName: "Analysis",
  }

  sales: RouteConfig = {
    parameterizedPath: `${this.legalEntity.parameterizedPath}/sales`,
    timeParameterOptions: {
      interval: Interval.Month,
    },
    moduleName: "Analysis",
  }

  // Revenue Transactions
  revenueTransactions: RouteConfig = {
    parentRoute: this.revenue,
    parameterizedPath: "/transactions",
  }

  // Revenue By Category
  revenueByCategories: RouteConfig = {
    parentRoute: this.revenue,
    parameterizedPath: "/categories",
  }

  revenueByCategoriesWaterfall: RouteConfig = {
    parentRoute: this.revenueByCategories,
    parameterizedPath: "/waterfall",
  }

  // Revenue By Customers
  revenueByCustomers: RouteConfig = {
    parentRoute: this.revenue,
    parameterizedPath: "/customers",
  }

  revenueByCustomersWaterfall: RouteConfig = {
    parentRoute: this.revenueByCustomers,
    parameterizedPath: "/waterfall",
  }

  // Reports
  reports: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/reports",
    timeParameterOptions: {
      interval: Interval.Month,
      defaultIntervalOrigin: RouteDefaultIntervalOrigin.PreviousMonth,
    },
    moduleName: "Reports",
  }

  // Used for marketing campaigns linking which can be agnostic of a user's legal entity.
  // Will redirect to the current legal entity and auto open reports browser.
  reportsRedirect: RouteConfig = {
    parameterizedPath: "/reports",
    moduleName: "Reports Start",
  }

  reportPackage: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/report/:packageId",
    detailsViewAction: DetailsViewAction.Push,
    getSharedObjectInfo: reportSharedObjectInfo,
    moduleName: "Report",
  }

  reportPackageVersion: RouteConfig = {
    parentRoute: this.reportPackage,
    parameterizedPath: "/v/:versionId",
    detailsViewAction: DetailsViewAction.Push,
    getSharedObjectInfo: reportSharedObjectInfo,
    moduleName: "Report",
  }

  reportPackageSharing: RouteConfig = {
    parentRoute: this.reportPackage,
    parameterizedPath: "/share",
    detailsViewAction: DetailsViewAction.Push,
    getSharedObjectInfo: reportSharedObjectInfo,
  }

  reportPackageVersionSharing: RouteConfig = {
    parentRoute: this.reportPackageVersion,
    parameterizedPath: "/share",
    detailsViewAction: DetailsViewAction.Push,
    getSharedObjectInfo: reportSharedObjectInfo,
  }

  reportPackageEditVersion: RouteConfig = {
    parentRoute: this.reportPackage,
    parameterizedPath: "/edit/:versionId",
    detailsViewAction: DetailsViewAction.Push,
    getSharedObjectInfo: reportSharedObjectInfo,
  }

  reportPackageVersionOptions: RouteConfig = {
    parentRoute: this.reportPackageEditVersion,
    parameterizedPath: "/options",
    detailsViewAction: DetailsViewAction.Push,
    getSharedObjectInfo: reportSharedObjectInfo,
  }

  reportStatementOptions: RouteConfig = {
    parentRoute: this.reportPackageVersionOptions,
    parameterizedPath: "/document/:documentId",
  }

  reportLayoutOptions: RouteConfig = {
    parentRoute: this.reportPackageVersionOptions,
    parameterizedPath: "/layout/:sectionId",
    detailsViewAction: DetailsViewAction.Push,
    getSharedObjectInfo: reportSharedObjectInfo,
  }

  reportAddLayoutComponent: RouteConfig = {
    parentRoute: this.reportPackageEditVersion,
    parameterizedPath: "/layout/:sectionId",
    detailsViewAction: DetailsViewAction.Push,
    getSharedObjectInfo: reportSharedObjectInfo,
  }

  // Boost
  qualityCheck: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/quality",
    timeParameterOptions: {
      interval: Interval.Month,
      defaultIntervalOrigin: RouteDefaultIntervalOrigin.AtLeast15Days,
    },
    moduleName: "Quality Check",
  }

  qualityCheckSettings: RouteConfig = {
    parentRoute: this.qualityCheck,
    parameterizedPath: "/settings",
  }

  boost: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/boost",
    moduleName: "Quality Check",
    timeParameterOptions: {
      interval: Interval.Month,
    },
  }

  // Category Review
  categoryReview: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/review",
    timeParameterOptions: {
      interval: Interval.Month,
      defaultIntervalOrigin: RouteDefaultIntervalOrigin.AtLeast15Days,
    },
    moduleName: "Review",
  }

  // Transaction Review
  transactionReview: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/transaction-review",
    timeParameterOptions: {
      interval: Interval.Month,
      defaultIntervalOrigin: RouteDefaultIntervalOrigin.AtLeast15Days,
    },
    moduleName: "Transaction Review",
  }

  transactionReviewItems: RouteConfig = {
    parentRoute: this.transactionReview,
    parameterizedPath: "/new",
  }

  transactionReviewPending: RouteConfig = {
    parentRoute: this.transactionReview,
    parameterizedPath: "/pending",
  }

  transactionReviewReplies: RouteConfig = {
    parentRoute: this.transactionReview,
    parameterizedPath: "/replies",
  }

  transactionReviewSettings: RouteConfig = {
    parentRoute: this.transactionReview,
    parameterizedPath: "/settings",
  }

  actionItems: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/action-items",
  }

  actionItem: RouteConfig = {
    parentRoute: this.actionItems,
    parameterizedPath: "/:workItemId",
  }

  actionItemsList: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/action-items-list",
  }

  clientTransactionReview: RouteConfig = {
    parentRoute: this.actionItemsList,
    parameterizedPath: "/transaction-review",
    timeParameterOptions: {
      interval: Interval.Month,
      defaultIntervalOrigin: RouteDefaultIntervalOrigin.CurrentMonth,
    },
    // mobile/tablet renders a Content view but desktop renders a Details view
    ...(isMobile ? undefined : { detailsViewAction: DetailsViewAction.Push }),
  }

  clientTransactionReviewItem: RouteConfig = {
    parentRoute: this.clientTransactionReview,
    parameterizedPath: "/:workItemId",
  }

  // Client Portal (LEGACY)
  clientPortal: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/portal",
    moduleName: "Client Portal",
  }

  // Used in emails (LEGACY)
  clientPortalActionItems: RouteConfig = {
    parentRoute: this.clientPortal,
    parameterizedPath: "/action-items",
  }

  // Legal Entity Settings
  legalEntitySettings: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/settings",
    moduleName: "Settings",
    detailsViewAction: DetailsViewAction.Push,
  }

  teamMemberSettings: RouteConfig = {
    parentRoute: this.legalEntitySettings,
    parameterizedPath: "/team-members",
  }

  addTeamMember: RouteConfig = {
    parentRoute: this.teamMemberSettings,
    parameterizedPath: "/add",
  }

  accountingSettings: RouteConfig = {
    parentRoute: this.legalEntitySettings,
    parameterizedPath: "/accounting",
  }

  objectMappingSettings: RouteConfig = {
    parentRoute: this.accountingSettings,
    parameterizedPath: "/object-mapping",
  }

  billPaySettings: RouteConfig = {
    parentRoute: this.legalEntitySettings,
    parameterizedPath: "/bill-pay",
  }

  billPayLedgerIntegrationSettings: RouteConfig = {
    parentRoute: this.billPaySettings,
    parameterizedPath: "/ledger-integration",
  }

  getPaidSettings: RouteConfig = {
    parentRoute: this.legalEntitySettings,
    parameterizedPath: "/get-paid",
  }

  integrationSettings: RouteConfig = {
    parentRoute: this.legalEntitySettings,
    parameterizedPath: "/integrations",
  }

  // KYB

  legalEntityIdentityVerification: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/identity-verification",
    detailsViewAction: DetailsViewAction.Push,
  }

  businessEntityType: RouteConfig = {
    parameterizedPath: "/business-entity-type",
    parentRoute: this.legalEntityIdentityVerification,
  }

  payComingSoon: RouteConfig = {
    parameterizedPath: "/coming-soon",
    parentRoute: this.legalEntityIdentityVerification,
  }

  KYC: RouteConfig = {
    parameterizedPath: "/person/:id",
    parentRoute: this.legalEntityIdentityVerification,
  }

  KYCProprietorName: RouteConfig = {
    parameterizedPath: "/proprietor-name",
    parentRoute: this.KYC,
  }

  KYCProprietorAddress: RouteConfig = {
    parameterizedPath: "/proprietor-address",
    parentRoute: this.KYC,
  }

  KYCProprietorIdentification: RouteConfig = {
    parameterizedPath: "/proprietor-identification",
    parentRoute: this.KYC,
  }

  KYCReview: RouteConfig = {
    parameterizedPath: "/review",
    parentRoute: this.KYC,
  }

  KYCEditInformation: RouteConfig = {
    parameterizedPath: "/edit-information",
    parentRoute: this.KYC,
  }

  KYB: RouteConfig = {
    parameterizedPath: "/business/:id",
    parentRoute: this.legalEntityIdentityVerification,
  }

  KYBBusinessName: RouteConfig = {
    parameterizedPath: "/business-name",
    parentRoute: this.KYB,
  }

  KYBBusinessAddress: RouteConfig = {
    parameterizedPath: "/business-address",
    parentRoute: this.KYB,
  }

  KYBBusinessIndustry: RouteConfig = {
    parameterizedPath: "/industry",
    parentRoute: this.KYB,
  }

  KYBTaxIdentification: RouteConfig = {
    parameterizedPath: "/tax-identification",
    parentRoute: this.KYB,
  }

  KYBPrimaryDecisionMaker: RouteConfig = {
    parameterizedPath: "/primary-decision-maker",
    parentRoute: this.KYB,
  }

  KYBUboName: RouteConfig = {
    parameterizedPath: "/ubo-name",
    parentRoute: this.KYB,
  }

  KYBUboAddress: RouteConfig = {
    parameterizedPath: "/ubo-address",
    parentRoute: this.KYB,
  }

  KYBUboPersonalInfo: RouteConfig = {
    parameterizedPath: "/ubo-info",
    parentRoute: this.KYB,
  }

  KYBUboIsOwner: RouteConfig = {
    parameterizedPath: "/ubo-owner",
    parentRoute: this.KYB,
  }

  KYBUboOtherOwners: RouteConfig = {
    parameterizedPath: "/other-owners",
    parentRoute: this.KYB,
  }

  KYBUboAnyOwners: RouteConfig = {
    parameterizedPath: "/any-owners",
    parentRoute: this.KYB,
  }

  KYBReview: RouteConfig = {
    parameterizedPath: "/review",
    parentRoute: this.KYB,
  }

  KYBReviewEditBusiness: RouteConfig = {
    parameterizedPath: "/edit-business",
    parentRoute: this.KYBReview,
  }

  KYBReviewEditDecisionMaker: RouteConfig = {
    parameterizedPath: "/edit-decision-maker",
    parentRoute: this.KYBReview,
  }

  KYBReviewNewOwner: RouteConfig = {
    parameterizedPath: "/new-owner",
    parentRoute: this.KYBReview,
  }

  KYBReviewEditOwner: RouteConfig = {
    parameterizedPath: "/edit-owner/:ownerId",
    parentRoute: this.KYBReview,
  }

  getPaid: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/get-paid",
    moduleName: "Get Paid",
  }

  // Departments
  departmentDetails: RouteConfig = {
    parameterizedPath: "/department/:departmentId",
    parentRoute: this.legalEntity,
    timeParameterOptions: {
      interval: Interval.Month,
      defaultIntervalOrigin: RouteDefaultIntervalOrigin.CurrentMonth,
    },
    detailsViewAction: DetailsViewAction.Push,
    moduleName: "Department",
    getSharedObjectInfo: departmentSharedObjectInfo,
  }

  departmentDetailsTransactions: RouteConfig = {
    parentRoute: this.departmentDetails,
    parameterizedPath: "/transactions",
  }

  departmentDetailsSubdepartments: RouteConfig = {
    parentRoute: this.departmentDetails,
    parameterizedPath: "/subdepartments",
  }

  // Category
  categoryDetails: RouteConfig = {
    parameterizedPath: "/category/:categoryId",
    parentRoute: this.legalEntity,
    timeParameterOptions: {
      interval: Interval.Month,
      defaultIntervalOrigin: RouteDefaultIntervalOrigin.CurrentMonth,
    },
    detailsViewAction: DetailsViewAction.Push,
    moduleName: "Category Details",
    getSharedObjectInfo: categorySharedObjectInfo,
  }

  categoryDetailsTransactions: RouteConfig = {
    parentRoute: this.categoryDetails,
    parameterizedPath: "/transactions",
  }

  categoryDetailsPartyRole: RouteConfig = {
    parentRoute: this.categoryDetails,
    parameterizedPath: `/:partyRole(${SUPPORTED_PARTY_ROLES_URL_KEYS.join("|")})`,
  }

  categoryDetailsPartyRoleOtherSide: RouteConfig = {
    parentRoute: this.categoryDetailsPartyRole,
    parameterizedPath: "/other-side",
  }

  categoryDetailsPartyRoleWaterfall: RouteConfig = {
    parentRoute: this.categoryDetailsPartyRole,
    parameterizedPath: "/waterfall",
  }

  categoryDetailsPartyRoleWaterfallOtherSide: RouteConfig = {
    parentRoute: this.categoryDetailsPartyRoleWaterfall,
    parameterizedPath: "/other-side",
  }

  categoryDetailsSubcategories: RouteConfig = {
    parentRoute: this.categoryDetails,
    parameterizedPath: "/subcategories",
  }

  categoryDetailsDepartments: RouteConfig = {
    parentRoute: this.categoryDetails,
    parameterizedPath: "/departments",
  }

  categoryDetailsComments: RouteConfig = {
    parentRoute: this.categoryDetails,
    parameterizedPath: "/comments",
  }

  // Metrics & Summaries Details views
  layoutComponentDetails: RouteConfig = {
    parameterizedPath: "/chart/:configType",
    parentRoute: this.legalEntity,
    timeParameterOptions: {
      interval: Interval.Month,
      defaultIntervalOrigin: RouteDefaultIntervalOrigin.CurrentMonth,
    },
    detailsViewAction: DetailsViewAction.Push,
    moduleName: "Chart",
  }

  // Affiliates (e.g. Accountants)

  organizationTeams: RouteConfig = {
    parentRoute: this.organization,
    parameterizedPath: "/teams",
    moduleName: "Teams",
  }

  organizationSettings: RouteConfig = {
    parentRoute: this.organization,
    parameterizedPath: "/settings",
    moduleName: "Settings",
  }

  organizationBilling: RouteConfig = {
    parentRoute: this.organization,
    parameterizedPath: "/billing",
    moduleName: "Current Plan",
    detailsViewAction: DetailsViewAction.Push,
  }

  organizationBillingUpgrade: RouteConfig = {
    parentRoute: this.organizationBilling,
    parameterizedPath: "/upgrade",
    moduleName: "Billing Plans",
    detailsViewAction: DetailsViewAction.Push,
  }

  organizationBillingCheckout: RouteConfig = {
    parentRoute: this.organizationBilling,
    parameterizedPath: "/checkout",
    detailsViewAction: DetailsViewAction.Push,
    moduleName: "Checkout",
  }

  organizationSettingsClients: RouteConfig = {
    parentRoute: this.organizationSettings,
    parameterizedPath: "/clients",
  }

  organizationSettingsClient: RouteConfig = {
    parentRoute: this.organizationSettingsClients,
    parameterizedPath: `${this.organization.parameterizedPath}/settings/client/:leSlug`,
    overrideParentParameterizedPath: true,
  }

  organizationSettingsEmployees: RouteConfig = {
    parentRoute: this.organizationSettings,
    parameterizedPath: "/employees",
  }

  organizationSettingsEmployee: RouteConfig = {
    parentRoute: this.organizationSettingsEmployees,
    parameterizedPath: `${this.organization.parameterizedPath}/settings/employee/:employeeId`,
    overrideParentParameterizedPath: true,
  }

  organizationFirmSettings: RouteConfig = {
    parentRoute: this.organizationSettings,
    parameterizedPath: "/firm",
  }

  // Connections
  connections: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/connections",
    detailsViewAction: DetailsViewAction.Push,
  }

  createConnection: RouteConfig = {
    parentRoute: this.connections,
    parameterizedPath: "/create",
  }

  createStripeConnection: RouteConfig = {
    parentRoute: this.createConnection,
    parameterizedPath: "/stripe",
  }

  createRampConnection: RouteConfig = {
    parentRoute: this.createConnection,
    parameterizedPath: "/ramp",
  }

  updateConnection: RouteConfig = {
    parentRoute: this.createConnection,
    parameterizedPath: "/:connectionId",
  }

  manageConnection: RouteConfig = {
    parentRoute: this.connections,
    parameterizedPath: "/manage/:connectionId",
  }

  allConnections: RouteConfig = {
    parentRoute: this.connections,
    parameterizedPath: "/all",
  }

  createNewConnection: RouteConfig = {
    parentRoute: this.allConnections,
    parameterizedPath: "/:connectorId",
  }

  // Connectors
  connectorOauth: RouteConfig = {
    parameterizedPath: "/oauth",
    moduleName: "Connectors",
  }

  stripeOauth: RouteConfig = {
    parentRoute: this.connectorOauth,
    parameterizedPath: "/stripe",
  }

  rampOauth: RouteConfig = {
    parentRoute: this.connectorOauth,
    parameterizedPath: "/ramp",
  }

  // Pay
  oldPayBills: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/pay-bills",
    moduleName: "Bill Pay",
  }

  billPay: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/bill-pay",
    moduleName: "Bill Pay",
  }

  billDetails: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/bill/:billId",
    detailsViewAction: DetailsViewAction.Push,
  }

  billDetailsComments: RouteConfig = {
    parentRoute: this.billDetails,
    parameterizedPath: "/comments",
  }

  billDetailsPayment: RouteConfig = {
    parentRoute: this.billDetails,
    parameterizedPath: "/payment",
    moduleName: "Bill Details",
  }

  billDetailsLineItems: RouteConfig = {
    parentRoute: this.billDetails,
    parameterizedPath: "/line-items",
  }

  billPaymentDetails: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/bill-payment/:billPaymentId",
    detailsViewAction: DetailsViewAction.Push,
  }

  billPaymentDetailsComments: RouteConfig = {
    parentRoute: this.billPaymentDetails,
    parameterizedPath: "/comments",
  }

  billPaymentDetailsBillInfo: RouteConfig = {
    parentRoute: this.billPaymentDetails,
    parameterizedPath: "/bill-info",
  }

  collectFunds: RouteConfig = {
    parameterizedPath: "/collect/:id",
  }

  // Invoicing
  invoicing: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/invoicing",
    moduleName: "Invoicing",
  }

  invoice: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/invoice",
    detailsViewAction: DetailsViewAction.Push,
  }

  invoiceCreate: RouteConfig = {
    parentRoute: this.invoice,
    parameterizedPath: "/create",
    detailsViewAction: DetailsViewAction.Push,
  }

  invoiceDetails: RouteConfig = {
    parentRoute: this.invoice,
    parameterizedPath: "/:id",
    detailsViewAction: DetailsViewAction.Push,
  }

  // Accounting
  accounting: RouteConfig = {
    parameterizedPath: "/transactions",
    parentRoute: this.legalEntity,

    timeParameterOptions: {
      interval: Interval.Month,
      defaultIntervalOrigin: RouteDefaultIntervalOrigin.CurrentMonth,
    },
  }

  accountingDetail: RouteConfig = {
    parentRoute: this.accounting,
    parameterizedPath: "/new",
    detailsViewAction: DetailsViewAction.Push,
    getSharedObjectInfo: transactionSharedObjectInfo,
    timeParameterOptions: undefined,
  }

  accountingDetailEdit: RouteConfig = {
    parentRoute: this.accounting,
    parameterizedPath: "/:transactionId",
    detailsViewAction: DetailsViewAction.Push,
    getSharedObjectInfo: transactionSharedObjectInfo,
    timeParameterOptions: undefined,
  }

  accountingDetailComments: RouteConfig = {
    parentRoute: this.accountingDetailEdit,
    parameterizedPath: "/comments",
    detailsViewAction: DetailsViewAction.Push,
  }

  accountingDetailDataSource: RouteConfig = {
    parentRoute: this.accountingDetail,
    parameterizedPath: "/:dataSourceId/:externalId/:externalLineItemId",
    detailsViewAction: DetailsViewAction.Push,
  }

  accountingDetailDataSourceComment: RouteConfig = {
    parentRoute: this.accountingDetailDataSource,
    parameterizedPath: "/comments",
    detailsViewAction: DetailsViewAction.Push,
  }

  accountingDetailLedgerTransactionId: RouteConfig = {
    parentRoute: this.accountingDetail,
    parameterizedPath: "/ledger/:ledgerTransactionId",
    detailsViewAction: DetailsViewAction.Push,
  }

  accountingDetailLedgerTransactionIdComment: RouteConfig = {
    parentRoute: this.accountingDetailLedgerTransactionId,
    parameterizedPath: "/comments",
    detailsViewAction: DetailsViewAction.Push,
  }

  // Deprecated Accounting Routes (here for redirect purposes)
  accountingDeprecated: RouteConfig = {
    parameterizedPath: "/accounting",
    parentRoute: this.legalEntity,

    timeParameterOptions: {
      interval: Interval.Month,
      defaultIntervalOrigin: RouteDefaultIntervalOrigin.CurrentMonth,
    },
  }

  accountingTransactionDashboardDeprecated: RouteConfig = {
    parameterizedPath: "/transaction-dashboard",
    parentRoute: this.accountingDeprecated,
    moduleName: "Transaction Dashboard",

    timeParameterOptions: {
      interval: Interval.Month,
      defaultIntervalOrigin: RouteDefaultIntervalOrigin.CurrentMonth,
    },
  }

  accountingDetailsDeprecated: RouteConfig = {
    parentRoute: this.legalEntity,
    parameterizedPath: "/accounting-details",
    detailsViewAction: DetailsViewAction.Push,
  }

  accountingTransactionDetailDeprecated: RouteConfig = {
    parentRoute: this.accountingDetailsDeprecated,
    parameterizedPath: "/transaction-detail",
    detailsViewAction: DetailsViewAction.Push,
    getSharedObjectInfo: transactionSharedObjectInfo,
  }

  accountingTransactionDetailEditRemoved: RouteConfig = {
    parentRoute: this.accountingTransactionDetailDeprecated,
    parameterizedPath: "/:transactionId",
    detailsViewAction: DetailsViewAction.Push,
  }

  accountingTransactionDetailCommentsRemoved: RouteConfig = {
    parentRoute: this.accountingTransactionDetailEditRemoved,
    parameterizedPath: "/comments",
    detailsViewAction: DetailsViewAction.Push,
  }

  accountingTransactionDetailDataSourceRemoved: RouteConfig = {
    parentRoute: this.accountingTransactionDetailDeprecated,
    parameterizedPath: "/:dataSourceId/:externalId/:externalLineItemId",
    detailsViewAction: DetailsViewAction.Push,
  }

  accountingTransactionDetailDataSourceCommentRemoved: RouteConfig = {
    parentRoute: this.accountingTransactionDetailDataSourceRemoved,
    parameterizedPath: "/comments",
    detailsViewAction: DetailsViewAction.Push,
  }

  accountingTransactionDetailLedgerTransactionIdRemoved: RouteConfig = {
    parentRoute: this.accountingTransactionDetailDeprecated,
    parameterizedPath: "/ledger/:ledgerTransactionId",
    detailsViewAction: DetailsViewAction.Push,
  }

  accountingTransactionDetailLedgerTransactionIdCommentRemoved: RouteConfig = {
    parentRoute: this.accountingTransactionDetailLedgerTransactionIdRemoved,
    parameterizedPath: "/comments",
    detailsViewAction: DetailsViewAction.Push,
  }

  // Vendor Directory
  vendorDirectory: RouteConfig = {
    parameterizedPath: "/vendors",
    parentRoute: this.legalEntity,

    timeParameterOptions: {
      interval: Interval.Month,
      defaultIntervalOrigin: RouteDefaultIntervalOrigin.CurrentMonth,
    },
  }

  // Vault
  vault: RouteConfig = {
    parameterizedPath: "/vault",
    parentRoute: this.legalEntity,
    moduleName: "Vault",
  }

  vaultFile: RouteConfig = {
    parameterizedPath: "/file/:fileId",
    parentRoute: this.vault,
    detailsViewAction: DetailsViewAction.Push,
  }

  // Reconciliations
  reconciliations: RouteConfig = {
    parameterizedPath: "/reconciliations",
    parentRoute: this.legalEntity,
    moduleName: "Reconciliations",
  }

  reconciliation: RouteConfig = {
    parameterizedPath: "/reconciliation/:reconciliationId",
    parentRoute: this.legalEntity,
    detailsViewAction: DetailsViewAction.Push,
  }

  viewOnlyReconciliation: RouteConfig = {
    parameterizedPath: "/reconciliation/:reconciliationId/view",
    parentRoute: this.legalEntity,
    detailsViewAction: DetailsViewAction.Push,
  }
}

export default createRoutes(FrontendStaticRoutes)

// Helper function that produces SharedObjectInfo for a report route
function reportSharedObjectInfo(routeParams: Record<string, string>): SharedObjectInfo {
  return {
    kind: ObjectKind.ReportPackage,
    id: routeParams.packageId || "",
    legalEntitySlug: routeParams.leSlug || "",
  }
}

// Helper function that produces SharedObjectInfo for a transaction route
function transactionSharedObjectInfo(routeParams: Record<string, string>): SharedObjectInfo {
  return {
    kind: ObjectKind.Transaction,
    id: routeParams.transactionId || "",
    legalEntitySlug: routeParams.leSlug || "",
  }
}

// Helper function that produces SharedObjectInfo for a category route
function categorySharedObjectInfo(routeParams: Record<string, string>): SharedObjectInfo {
  return {
    kind: ObjectKind.Category,
    id: routeParams.categoryId || "",
    legalEntitySlug: routeParams.leSlug || "",
  }
}

// Helper function that produces SharedObjectInfo for a department route
function departmentSharedObjectInfo(routeParams: Record<string, string>): SharedObjectInfo {
  return {
    kind: ObjectKind.Department,
    id: routeParams.departmentId || "",
    legalEntitySlug: routeParams.leSlug || "",
  }
}

// Helper function that produces SharedObjectInfo for a party route
function partySharedObjectInfo(routeParams: Record<string, string>): SharedObjectInfo {
  return {
    kind: ObjectKind.Party,
    id: routeParams.partyId || "",
    legalEntitySlug: routeParams.leSlug || "",
  }
}
