import * as React from "react"
import { ErrorCode, FileRejection, useDropzone } from "react-dropzone"
import { useApolloClient } from "@apollo/client"
import { ApolloCache } from "@apollo/client/cache"
import { isArray } from "@apollo/client/utilities"
import {
  AsyncProcessingStatus,
  DocumentsSummary,
  DocumentStatus,
  IngestSource,
  ListVaultFilesDocument,
  ListVaultFilesQuery,
  VaultFileFieldsFragment,
  VaultFormatFieldsFragment,
  VaultSegmentFieldsFragment,
} from "@digits-graphql/frontend/graphql-bearer"
import { SvgAlertTriangleSolid } from "@digits-shared/components/SVGIcons/solid/AlertTriangleSolid.svg"
import { SvgUploadCloud01Solid } from "@digits-shared/components/SVGIcons/solid/UploadCloud01Solid.svg"
import envHelper from "@digits-shared/helpers/envHelper"
import fileHelper from "@digits-shared/helpers/fileHelper"
import stringHelper from "@digits-shared/helpers/stringHelper"
import useSession from "@digits-shared/hooks/useSession"
import { authorizationBearer } from "@digits-shared/session/authorizationBearer"
import moment from "moment"
import { TrackJS } from "trackjs"
import { v4 as generateUUID } from "uuid"
import { VaultFile } from "src/frontend/components/OS/Springboard/Applications/Vault/types"
import { useVaultQueryParams } from "src/frontend/components/OS/Springboard/Applications/Vault/useVaultQueryParams"
import { useChromeAlertContext } from "src/frontend/components/Shared/Alerts/ChromeAlertsContext"
import FrontendSession from "src/frontend/session"

export const VAULT_MAX_FILE_SIZE = 50 * (1024 * 1024) // 50MB
export const VAULT_MAX_FILES = 25

interface UploadParams {
  legalEntityId: string
  source: IngestSource
  collectionId?: string
}

export function useDragAndDropFileUpload(disabled: boolean, collectionId?: string) {
  const filesUpload = useFilesUpload(collectionId)
  const { addAlert } = useChromeAlertContext()

  const onDropAccepted = React.useCallback(
    (filesToUpload: File[] | File) => filesUpload(filesToUpload),
    [filesUpload]
  )

  const onDropRejected = React.useCallback(
    (rejections: FileRejection[]) => {
      const error = rejections[0]?.errors?.[0]

      const code = error?.code ?? ErrorCode.FileInvalidType
      let message =
        (code === ErrorCode.FileInvalidType ? "File type not supported." : error?.message) ||
        "Upload failed."

      message = message
        .replace(/(\d+ bytes)/, (_, capture) => {
          const bytes = parseInt(capture, 10)
          return fileHelper.formatBytes(bytes)
        })
        .replace(/(.{50})..+/, "$1…")

      addAlert({
        id: generateUUID(),
        message,
        SVGComponent: SvgAlertTriangleSolid,
        type: "warning",
        autoDismiss: true,
      })
    },
    [addAlert]
  )

  const dropzone = useDropzone({
    disabled,
    onDropAccepted,
    onDropRejected,
    maxSize: VAULT_MAX_FILE_SIZE,
    maxFiles: VAULT_MAX_FILES,
    multiple: true,
  })

  return { dropzone, onDropAccepted, onDropRejected }
}

export function useFilesUpload(collectionId?: string) {
  const session = useSession<FrontendSession>()
  const { currentLegalEntityId: legalEntityId } = session
  const { addAlert, removeAlert } = useChromeAlertContext()
  const client = useApolloClient()
  const createUploadPlaceholder = useCreateUploadPlaceholder(collectionId)

  return React.useCallback(
    (filesToUpload: File[] | File) => {
      const files = isArray(filesToUpload) ? filesToUpload : [filesToUpload]

      const alertId = generateUUID()
      const rejection = rejectUpload(files)
      if (rejection) {
        addAlert({
          id: alertId,
          message: rejection,
          SVGComponent: SvgAlertTriangleSolid,
          type: "warning",
          autoDismiss: true,
        })
        return
      }

      const alertText = stringHelper.pluralize(files.length, "document", "documents")
      addAlert({
        id: alertId,
        message: `Uploading ${alertText}…`,
        autoDismiss: false,
        type: "info",
        SVGComponent: SvgUploadCloud01Solid,
      })

      const source = IngestSource.Vault

      const token = session.bearer()

      const uploadParams = {
        legalEntityId,
        source,
        collectionId,
      }

      // Upload all files first in parallel
      const uploadPromises = files.map((file) => {
        const placeholder = createUploadPlaceholder(file)
        return createUploadPromise(file, token, uploadParams)
          .then((json) => ({
            placeholder,
            json,
          }))
          .catch((e) => {
            deleteUploadPlaceholder(client.cache, placeholder)
            return e
          })
      })

      // remove placeholders and alert
      return Promise.all(uploadPromises)
        .then(async (uploads) => {
          await client.refetchQueries({ include: [ListVaultFilesDocument] })
          return uploads
        })
        .then((uploads) => {
          uploads.forEach(({ placeholder }) => deleteUploadPlaceholder(client.cache, placeholder))
        })
        .finally(() => {
          setTimeout(() => removeAlert(alertId), 500)
        })
    },
    [addAlert, session, legalEntityId, collectionId, createUploadPlaceholder, client, removeAlert]
  )
}

function rejectUpload(files: File[]) {
  if (files.length > VAULT_MAX_FILES) {
    return "Too many files"
  }

  for (const file of files) {
    if (file.size > VAULT_MAX_FILE_SIZE) {
      return `File is larger than ${fileHelper.formatBytes(VAULT_MAX_FILE_SIZE)}`
    }
  }
  return null
}

function createUploadPromise(
  files: File,
  bearer: Promise<string | undefined>,
  uploadParams: UploadParams
): Promise<{ document_id: string; collection_id: string; bearer: string }> {
  const url = new URL(`${process.env.DOCUMENT_UPLOAD_ENDPOINT}`)
  url.searchParams.set("legal-entity-id", uploadParams.legalEntityId)
  url.searchParams.set("source", uploadParams.source)
  if (uploadParams.collectionId) {
    url.searchParams.set("document-collection-id", uploadParams.collectionId)
  }

  const upload = isArray(files) ? files[0] : files
  const payload = new FormData()
  payload.append("upload", upload)

  return bearer.then((token) => {
    const headers = {
      ...authorizationBearer(token),
    }

    return fetch(url, { method: "post", headers, body: payload }).then((resp) => {
      if (!resp.ok) throw new Error(resp.statusText)

      return resp.json()
    })
  })
}

function useCreateUploadPlaceholder(collectionId?: string) {
  const { cache: store } = useApolloClient()
  const variables = useVaultQueryParams(collectionId)

  return React.useCallback(
    (file: File) => {
      const queryOptions = {
        query: ListVaultFilesDocument,
        variables,
      }

      const data = store.readQuery<ListVaultFilesQuery>(queryOptions)
      const files = data?.listDocuments || []
      const summary = data?.summarizeDocuments || { count: 0 }

      const sourceSegment: VaultSegmentFieldsFragment = {
        __typename: "Segment",
        collectionId: generateUUID(),
        fileId: generateUUID(),
        fileName: file.name,
        fileSize: file.size,
        sequence: 0,
        token: "",
      }

      const sourceFormat: VaultFormatFieldsFragment = {
        __typename: "Format",
        id: generateUUID(),
        formatClass: null,
        mimeType: file.type,
        segments: [sourceSegment],
      }

      const placeholder: VaultFileFieldsFragment = {
        __typename: "RawDocument",
        id: generateUUID(),
        collectionId: generateUUID(),
        sourceFormatId: sourceFormat.id,
        formats: [sourceFormat],
        features: [],
        creator: null,
        createdAt: moment().utc(true).unix(),
        updatedAt: moment().utc(true).unix(),
        status: DocumentStatus.New,
        asyncProcessingStatus: AsyncProcessingStatus.UnknownAsyncProcessingStatus,
      }
      const listDocuments = [placeholder, ...files]

      const summarizeDocuments: DocumentsSummary = {
        ...summary,
        count: summary.count + 1,
      }

      store.writeQuery<ListVaultFilesQuery>({
        ...queryOptions,
        data: { listDocuments, summarizeDocuments },
        broadcast: true,
      })

      // Check caching working properly
      const cachedList = store.readQuery<ListVaultFilesQuery>({
        ...queryOptions,
      })
      if (!cachedList || files.length + 1 !== cachedList?.listDocuments.length) {
        if (!envHelper.isProduction()) {
          // If this error is thrown it means one of the graphl fragments
          // was modified and we can no longer cache the thread object.
          // This must be corrected.
          throw new Error(
            `Vault placeholder was not cached, pre-count: ${files.length}, post: ${cachedList?.listDocuments.length}`
          )
        }
        TrackJS.track(new Error("Vault placeholder was not cached"))
      }

      return placeholder
    },
    [store, variables]
  )
}

function deleteUploadPlaceholder(store: ApolloCache<object>, placeholder: VaultFile) {
  if (!placeholder) return
  store.evict({ id: store.identify(placeholder), broadcast: true })
}
