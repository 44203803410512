// NOTE: These keys are sorted in the order that we want to show the results
export enum SearchResultType {
  Category = "Category",
  UnpaidCustomer = "Receivable",
  Customer = "Customer",
  Supplier = "Supplier",
  OwedVendor = "Payable",
  PrepaidVendor = "Prepaid Vendor",
  Vendor = "Vendor",
  Lender = "Lender",
  Shareholder = "Shareholder",
  Comment = "Comment",
  Report = "Report",
  User = "User",
  Invoice = "Invoice",
  Department = "Department",
  Transaction = "Transaction",
}
