import { LegalEntityStatus, ViewType } from "@digits-graphql/frontend/graphql-bearer"
import DoppelgangerAccessLevel from "@digits-shared/session/DGAccessLevel"
import {
  JWTAccountingViewVersions,
  JWTLegalEntity,
} from "@digits-shared/session/jwt/jwtLegalEntity"
import { AspectCode } from "@digits-shared/session/SessionTypes"
import moment from "moment"

export enum ViewTypeCode {
  Accrual = "a",
  Ledger = "l",
  AIBookeeper = "k",
}

export interface SessionLegalEntityAttributes {
  readonly id: string
  readonly slug: string
  readonly name: string
  readonly status: LegalEntityStatus
  readonly accountingViewVersions: Map<ViewType, string>
  readonly mutationVersions: Map<ViewType, string>

  readonly organizationId: string
  readonly affiliationId?: string

  readonly aspects: Map<AspectCode, string>
  readonly termsAcceptedAt: Date | undefined
}

/**
 * Simple wrapper for legal entities stored on JWT
 *
 * Provides convenience methods, better naming
 * than what is found on the intentionally abbreviated JWT,
 * and clean types.
 */
export default class SessionLegalEntity {
  private readonly attributes: SessionLegalEntityAttributes

  constructor(organizationId: string, rawEntity: JWTLegalEntity, affiliationId?: string) {
    const aspects = new Map<AspectCode, string>()
    rawEntity.aspects?.forEach((aspect) => aspects.set(aspect as AspectCode, aspect))

    const terms = rawEntity?.terms
    const termsAcceptedAt = terms ? moment(terms, "YYYY-MM-DD").toDate() : undefined

    const accountingViewVersions = mapViewVersions(rawEntity.avvs)
    const mutationVersions = mapViewVersions(rawEntity.mvvs)

    this.attributes = {
      ...rawEntity,
      organizationId,
      affiliationId,
      aspects,
      accountingViewVersions,
      mutationVersions,
      termsAcceptedAt,
    }
  }

  /*
    ACCESSORS
  */

  get id() {
    return this.attributes.id
  }

  get slug() {
    return this.attributes.slug
  }

  get name() {
    return this.attributes.name
  }

  get status() {
    return this.attributes.status
  }

  get accountingViewVersions() {
    return this.attributes.accountingViewVersions
  }

  get mutationViewVersions() {
    return this.attributes.mutationVersions
  }

  get organizationId() {
    return this.attributes.organizationId
  }

  get affiliationId() {
    return this.attributes.affiliationId
  }

  get aspects() {
    return this.attributes.aspects
  }

  get termsAcceptedAt() {
    return this.attributes.termsAcceptedAt
  }

  /*
    CONVENIENCE METHODS
  */

  get isApproved() {
    return this.status === LegalEntityStatus.Approved
  }

  get isActive() {
    return this.status === LegalEntityStatus.Active
  }

  hasAccessToAspect(aspect?: AspectCode) {
    return !!aspect && this.aspects.has(aspect)
  }

  hasDashboardAccess(doppelganger?: DoppelgangerAccessLevel) {
    return this.isApproved || this.isActive || doppelganger?.hasDashboardAccess
  }

  /*
    For now, consider Pending and PendingHold to mean the same thing.
   */
  get isPending() {
    return (
      this.status === LegalEntityStatus.Pending || this.status === LegalEntityStatus.PendingHold
    )
  }

  get isNew() {
    return this.status === LegalEntityStatus.New
  }

  get isAffiliated() {
    return !!this.affiliationId
  }
}

function mapViewVersions(viewVersions: JWTAccountingViewVersions) {
  return Object.entries(viewVersions ?? {}).reduce((map, [vt, avv]) => {
    switch (vt) {
      case ViewTypeCode.Ledger:
        map.set(ViewType.Ledger, avv)
        break
      case ViewTypeCode.AIBookeeper:
        map.set(ViewType.AIBookkeeper, avv)
        break
    }
    return map
  }, new Map<ViewType, string>())
}
