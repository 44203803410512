import * as React from "react"
import { PartyDesignation } from "@digits-graphql/frontend/graphql-bearer"
import { svgIconStyles, svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgRevenue } from "@digits-shared/components/SVGIcons/category/Revenue.svg"
import { SvgUser01 } from "@digits-shared/components/SVGIcons/line/User01.svg"
import { SvgFoodBeverageSolid } from "@digits-shared/components/SVGIcons/solid/FoodBeverageSolid.svg"
import { ColorIcon } from "@digits-shared/components/UI/Elements/ColorIcon"
import {
  DetailsAsideIconContainer,
  ExtraLargeIconContainer,
  IconContainer,
  IconContainerProps,
  IconShape,
  IconSize,
  IconSVGContainer,
  InheritIconContainer,
  LargeIconContainer,
  LargestIconContainer,
  MediumIconContainer,
  MediumLargeIconContainer,
  MediumLargerIconContainer,
  MediumLargestIconContainer,
  SmallIconContainer,
  SmallMediumIconContainer,
  TinyIconContainer,
} from "@digits-shared/components/UI/Icons/Icon"
import { VibrantIcon } from "@digits-shared/components/UI/Icons/VibrantIcon"
import stringHelper from "@digits-shared/helpers/stringHelper"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { VibrantBackgroundValues } from "src/shared/components/Elements/VibrantBackground"
import { DEFAULT_PARTY_ICON_SIZE } from "src/shared/components/PartyHover/index"

/*
  STYLES
*/

const VendorsVGStyled = styled(SvgRevenue)`
  padding: 1px 3px 0 2px;
  ${svgIconStyles(colors.white)};
`

const PeoplePartiesVGStyled = styled(SvgUser01)`
  ${svgPathStyles(colors.white, 1.5)};
`

const FoodBevStyled = styled(SvgFoodBeverageSolid)`
  ${svgIconStyles(colors.white)};
`

/*
  INTERFACES
*/

export interface PartyAttributesForIcon {
  iconUrl?: string | null
  iconObjectName?: string | null
  iconBlob?: string
  designation?: PartyDesignation
  name?: string
}

interface Props {
  party?: PartyAttributesForIcon | null
  partyImageRef?: React.RefObject<HTMLImageElement>
  size?: IconSize
  className?: string
  background?: VibrantBackgroundValues
}

/*
  COMPONENTS
*/

export const PartyIcon: React.FC<Props & IconContainerProps> = ({
  size = DEFAULT_PARTY_ICON_SIZE,
  disableHoverBorder,
  isSelected,
  ...props
}) => {
  const { className, background, partyImageRef, party } = props
  const iconShape = useIconShape(party?.designation)

  if (!party) {
    return null
  }

  const colorIconSize = size === IconSize.Inherit ? "inherit" : size.pixels
  const { iconUrl, iconBlob, name, designation } = party

  let icon
  switch (true) {
    case !name:
      icon = (
        <IconSVGContainer>
          <SvgIcon designation={designation} />
        </IconSVGContainer>
      )
      break

    // PeopleDesignation is a special case where we want to show the initials of the name
    // even if a icon is provided
    case designation === PartyDesignation.PeopleDesignation:
      icon = (
        <ColorIcon
          fallbackText={stringHelper.firstInitial(name)}
          size={colorIconSize}
          displayFallbackText
          iconShape={iconShape}
        />
      )
      break

    case Boolean(iconUrl || iconBlob):
      icon = (
        <VibrantIcon
          ref={partyImageRef}
          {...background}
          src={iconUrl || iconBlob}
          iconShape={iconShape}
        />
      )
      break

    default:
      icon = (
        <ColorIcon
          fallbackText={stringHelper.firstInitial(name)}
          size={colorIconSize}
          displayFallbackText
          iconShape={iconShape}
        />
      )
      break
  }

  const PartyIconContainer = getIconContainer(size)
  return (
    <PartyIconContainer
      className={className}
      disableHoverBorder={disableHoverBorder}
      isSelected={isSelected}
      iconShape={iconShape}
    >
      {icon}
    </PartyIconContainer>
  )
}

function getIconContainer(size?: IconSize): React.ComponentType<IconContainerProps> {
  switch (size) {
    case IconSize.Inherit:
      return InheritIconContainer

    case IconSize.Largest:
      return LargestIconContainer

    case IconSize.ExtraLarge:
      return ExtraLargeIconContainer

    case IconSize.Large:
      return LargeIconContainer

    case IconSize.MediumLarger:
      return MediumLargerIconContainer

    case IconSize.MediumLargest:
      return MediumLargestIconContainer

    case IconSize.DetailsAside:
      return DetailsAsideIconContainer

    case IconSize.MediumLarge:
      return MediumLargeIconContainer

    case IconSize.Medium:
      return MediumIconContainer

    case IconSize.SmallMedium:
      return SmallMediumIconContainer

    case IconSize.Small:
      return SmallIconContainer

    case IconSize.Tiny:
      return TinyIconContainer

    case undefined:
    default:
      return IconContainer
  }
}

const SvgIcon: React.FC<{ designation: PartyDesignation | undefined }> = ({ designation }) => {
  const IconComponent = svgIconComponent(designation)
  return <IconComponent />
}

export function svgIconComponent(
  designation: PartyDesignation | undefined
): React.ComponentType<React.SVGProps<SVGSVGElement>> {
  switch (designation) {
    case PartyDesignation.PeopleDesignation:
      return PeoplePartiesVGStyled
    case PartyDesignation.SMBMealsDesignation:
      return FoodBevStyled
    default:
      return VendorsVGStyled
  }
}

const useIconShape = (designation: PartyDesignation | undefined) =>
  designation === PartyDesignation.BusinessDesignation ||
  designation === PartyDesignation.SMBMealsDesignation ||
  designation === PartyDesignation.SMBOtherDesignation
    ? IconShape.Square
    : IconShape.Circle
