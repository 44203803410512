import {
  SortDirection,
  Transaction,
  TransactionColumnName,
} from "@digits-graphql/frontend/graphql-bearer"
import { TransactionsTableContextProps } from "src/shared/components/Transactions/TransactionsTableContext"

export const SYNTHESIZED_TRANSACTION_CLASS_NAME = "synthesized-transaction"
export const QUICKBOOKS_INSTITUTION_NAME = "QuickBooks"
export const XERO_INSTITUTION_NAME = "Xero"

/*
  INTERFACES
*/

export interface TransactionsTableProps {
  isLoading?: boolean
  loadingRows?: number
  error?: Error
  transactions?: Transaction[]
  rowSubtitle?: TransactionTableRowSubtitle
  detailsAction: TransactionTableDetailsAction
  tableContext: TransactionsTableContextProps
  sortByColumnName?: TransactionColumnName
  sortDirection?: SortDirection
  onSortByColumnChange?: (sortBy: TransactionColumnName, sortDirection: SortDirection) => void
  scrollTo?: (top: number) => void
  neverHoverParty?: boolean
  paddingCellWidth?: number
}

export enum TransactionTableRowSubtitle {
  Category = "category",
  Department = "department",
}

export enum TransactionTableDetailsAction {
  Expand = "Expand",
  Overlay = "Overlay",
  None = "None",
}

export interface ExpandedGroup {
  [key: string]: string[]
}
