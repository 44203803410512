import {
  CategoryType,
  EntityCategory,
  EntityDepartment,
  EntityParty,
  IntervalOrigin,
} from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { validate as validateUUID } from "uuid"
import { useComponentIntervalOrigin } from "src/frontend/components/Shared/Layout/hooks/useComponentIntervalOrigin"
import routes from "src/frontend/routes"
import FrontendSession from "src/frontend/session"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

function isCategoryEntity(
  entity: EntityCategory | EntityParty | EntityDepartment
): entity is EntityCategory {
  return !!(entity as EntityCategory).type
}

function isDepartmentEntity(
  entity: EntityCategory | EntityParty | EntityDepartment
): entity is EntityDepartment {
  return !isCategoryEntity(entity) && "parentId" in entity
}

export function useEntityLink(
  entity: EntityParty | EntityCategory | EntityDepartment | undefined,
  customOrigin?: IntervalOrigin
) {
  const {
    currentLegalEntity: { slug },
    isSharingContextActive,
  } = useSession<FrontendSession>()
  const componentOrigin = useComponentIntervalOrigin()
  const intervalOrigin = useIntervalOrigin()
  const origin = customOrigin ?? componentOrigin ?? intervalOrigin

  if (isSharingContextActive || !entity) return undefined

  if (isCategoryEntity(entity)) {
    return categoryLink(entity, slug, origin)
  }

  if (isDepartmentEntity(entity)) {
    return departmentLink(entity, slug, origin)
  }

  return partyLink(entity, slug, origin)
}

export function partyLink(party: EntityParty, leSlug: string, intervalOrigin: IntervalOrigin) {
  const valid = party && validateUUID(party.id)
  if (!valid) return undefined

  const partyRole = party.roles?.[0] || FrontendPartyRole.Vendor.partyRole

  return routes.partyDetails.generateFromCurrentPath({
    leSlug,
    partyId: party.id,
    partyRole: FrontendPartyRole.roleURLKey(partyRole),
    ...intervalOrigin,
  })
}

function categoryLink(category: EntityCategory, leSlug: string, intervalOrigin: IntervalOrigin) {
  const valid = category && category.type !== CategoryType.UnknownType && validateUUID(category.id)
  if (!valid) return undefined

  return routes.categoryDetails.generateFromCurrentPath({
    leSlug,
    categoryId: category.id,
    ...intervalOrigin,
  })
}

function departmentLink(
  department: EntityDepartment,
  leSlug: string,
  intervalOrigin: IntervalOrigin
) {
  const valid = department && validateUUID(department.id)
  if (!valid) return undefined

  return routes.departmentDetails.generateFromCurrentPath({
    leSlug,
    departmentId: department.id,
    ...intervalOrigin,
  })
}
