import { LegalEntityStatus, ViewType } from "@digits-graphql/frontend/graphql-bearer"
import { defined } from "@digits-shared/helpers/filters"
import objectHelper from "@digits-shared/helpers/objectHelper"
import { JWTAffiliation, JWTLegalEntity } from "@digits-shared/session/jwt/jwtLegalEntity"
import { JWTSession } from "@digits-shared/session/jwt/jwtSession"
import { JWTEmployment } from "@digits-shared/session/jwt/jwtUser"
import { SessionPermissions } from "@digits-shared/session/Permissions"
import Session from "@digits-shared/session/Session"
import { AspectCode } from "@digits-shared/session/SessionTypes"
import SessionUser from "@digits-shared/session/User"
import memoizeOne from "memoize-one"
import moment from "moment"
import TOS_VERSION from "static/documents/terms-of-service-version.txt?raw"
import { FrontendPermissionModule } from "src/frontend/session/permissionModule"
import { Experience } from "src/frontend/session/personas"
import AssetsHelper, { CDNAsset } from "src/shared/helpers/assetsHelper"
import SessionAffiliation from "./Affiliation"
import SessionAffiliationOrganization from "./AffiliationOrganization"
import SessionBusinessOrganization, {
  SessionBusinessOrganizationAttributes,
} from "./BusinessOrganization"
import SessionLegalEntity from "./LegalEntity"

export * from "./BusinessOrganization"

/**
 * Session specifically for frontend webapp
 */
export default class FrontendSession extends Session<JWTSession> {
  static CURRENT_ORG_ID_STORAGE_NAMESPACE = "organization.current"
  static CURRENT_LE_ID_STORAGE_NAMESPACE = "legalEntity.current"

  sharingOrganization: SessionBusinessOrganization | undefined
  sharingLegalEntity: SessionLegalEntity | undefined
  sharingViewType: ViewType | undefined

  // Holds the orgs which come from the JWT
  private sessionOrganizations: SessionBusinessOrganization[]
  private sessionOrganization: SessionBusinessOrganization | undefined
  private sessionLegalEntity: SessionLegalEntity | undefined

  constructor() {
    super()
    // Must call FrontendSession::decodeSession to correctly set instance fields which are not
    // set by super call.
    this.decodeSession()
    this.setFromStorage()
  }

  /**
   * Returns true if a sharing context is active.
   */
  get isSharingContextActive() {
    return !!this.sharingLegalEntity
  }

  /**
   * Applies the provided organization and legal entity to the session as the current sharing context.
   * Those provided values will take precedence over other legal entities and organizations known to the
   * session when determining the current state of the session.
   */
  setSharingContext(
    sharingOrganization: SessionBusinessOrganization,
    sharingLegalEntity: SessionLegalEntity,
    sharingViewType = ViewType.Ledger
  ) {
    this.sharingOrganization = sharingOrganization
    this.sharingLegalEntity = sharingLegalEntity
    this.sharingViewType = sharingViewType
    this.currentOrganization = sharingOrganization
    this.currentLegalEntity = sharingLegalEntity
  }

  /**
   * Clears organization and legal entity overrides applied for a sharing context.
   */
  clearSharingContext() {
    this.sharingOrganization = undefined
    this.sharingLegalEntity = undefined
    this.sharingViewType = undefined
    this.currentOrganization = undefined
    this.clearCurrentLegalEntity()
  }

  /**
   * Provides the list of currently known organizations, inclusive of the current sharing organization,
   * if present.
   */
  get organizations() {
    if (!this.sharingOrganization) return this.sessionOrganizations

    const orgs = [...this.sessionOrganizations]
    orgs.unshift(this.sharingOrganization)
    return orgs
  }

  /**
   * Provides the list of currently known legal entities across all organizations in the JWT
   */
  get legalEntities() {
    return this.organizations.flatMap((org) => org.legalEntities).filter(defined)
  }

  /**
   * Provides the list of currently known affiliations across all organizations in the JWT
   */
  get affiliations() {
    return this.organizations.flatMap((org) => org.affiliations).filter(defined)
  }

  /**
   * Provides the list of currently known organizations on the users JWT. Explicitly not including
   * sharing organizations.
   */
  get jwtOrganizations() {
    return this.sessionOrganizations
  }

  /**
   * Get the current organization. Used either the organization referenced in memory
   * or uses a fallback.
   *
   * @return {SessionBusinessOrganization|undefined} The current organization
   */
  get currentOrganization(): SessionBusinessOrganization | undefined {
    if (this.sessionOrganization) return this.sessionOrganization

    this.sessionOrganization = this.findCurrentOrganization()

    return this.sessionOrganization
  }

  /**
   * Set the current organization in session. If not a sharing organization, persist it to
   * local and session storage.
   *
   * Persisting will keep the current organization in local and session storage and key
   * off of the user. We won't clear this on logout so the user can return to their last
   * org after logging back in.
   * @param {SessionBusinessOrganization} organization The current organization to set
   * @return {void}
   */
  set currentOrganization(organization: SessionBusinessOrganization | undefined) {
    if (!organization) {
      this.sessionOrganization = undefined
      return
    }

    if (!this.findOrganizationById(organization.id)) return

    this.sessionOrganization = organization
    if (organization.id !== this.sharingOrganization?.id) {
      this.persistCurrentOrganizationId(organization.id)
    }
  }

  /**
   * Determine the current organization. Will use a fallback of current legal entity's organization
   * if we don't have a current organization referenced in memory.
   *
   * @return {SessionLegalEntity|undefined} The current legal entity
   */
  private findCurrentOrganization(): SessionBusinessOrganization | undefined {
    if (this.sessionOrganization) return this.sessionOrganization

    // Look up by current legal entity so that they always match
    const { currentLegalEntity } = this

    // If we find a stored current legal entity, look for in the available organizations
    if (currentLegalEntity) {
      const optionalCurrentOrg = this.findOrganizationByLegalEntityId(currentLegalEntity.id)
      if (optionalCurrentOrg) return optionalCurrentOrg
    }

    return undefined
  }

  /**
   * Get the current affiliated organization.
   * @return {SessionBusinessOrganization|undefined} The current affiliated organization
   */
  get currentAffiliatedOrganization() {
    return this.currentAffiliation?.organization
  }

  /*
   * Get the parent organization id for the current legal entity.
   * For affiliated users, this would be the current affiliated organization;
   * for org members this would be the current organization which has a fallback
   * value of the first organization the user is an employee of.
   */
  get currentLegalEntityParentOrganizationId() {
    return this.currentAffiliatedOrganization?.id || this.currentOrganizationId
  }

  /**
   * Get the current organization id.
   *
   * @return {string} The current organization id
   */
  get currentOrganizationId() {
    return this.currentOrganization?.id
  }

  // Get the last known Legal Entity Id from the local storage
  get lastKnownOrganizationId() {
    // Look up the current org id stored in user preferences.
    return (
      this.storage.session.getItem(FrontendSession.CURRENT_ORG_ID_STORAGE_NAMESPACE) ??
      this.getGlobalPreference(FrontendSession.CURRENT_ORG_ID_STORAGE_NAMESPACE)
    )
  }

  /**
   * Find a stored organization in the session by the id
   *
   * @param {string} id The id of the organization
   * @return {SessionBusinessOrganization | undefined} The found organization or undefined if not found
   */
  findOrganizationById(id: string) {
    return this.organizations.find((organization) => organization.id === id)
  }

  /**
   * Find a stored organization in the session by the slug
   *
   * This is useful for when you only have a partial reference to an org.
   * For example, checking organization slug in the url to make sure it is valid.
   * @param {string} slug The slug of the organization to check if valid
   * @return {SessionBusinessOrganization | undefined} The found organization or undefined if not found
   */
  findOrganizationBySlug(slug: string) {
    return this.organizations.find((organization) => organization.slug === slug)
  }

  /**
   * Find a stored organization in the session by a legal entity ID, it could be part of the org or
   * via affiliations
   *
   * @param {string} legalEntityId The id of the legal entity to search by
   * @return {SessionBusinessOrganization | undefined} The found organization or undefined if not found
   */
  findOrganizationByLegalEntityId(legalEntityId: string) {
    return this.organizations.find(
      (org) =>
        org.legalEntities.some((le) => le.id === legalEntityId) ||
        org.affiliatedLegalEntities.some((le) => le.id === legalEntityId)
    )
  }

  /**
   * Create a new organization and add it to the stored array of organizations in the session.
   *
   * NOTE: This does not persist reloads so should only be done after a success creation
   * of an organization to the server.
   *
   * @param {SessionBusinessOrganizationAttributes} organizationAttributes Attributes used to
   * create a new organization
   */
  addOrganization(organizationAttributes: SessionBusinessOrganizationAttributes) {
    // See if we already have this org in our session, if so remove it.
    this.sessionOrganizations = this.sessionOrganizations.filter(
      (org) => org.id !== organizationAttributes.id
    )
    const newOrganization = new SessionBusinessOrganization(organizationAttributes)
    this.sessionOrganizations.push(newOrganization)
    return newOrganization
  }

  /**
   * Get the current legal entity. Used either the legal entity referenced in memory
   * or uses a fallback.
   *
   * @return {SessionLegalEntity|undefined} The current legal entity
   */
  get currentLegalEntity() {
    if (this.sessionLegalEntity) return this.sessionLegalEntity

    this.sessionLegalEntity = this.findCurrentLegalEntity()

    return this.sessionLegalEntity
  }

  /**
   * Set the current legal entity. If not a sharing legal entity, persist it to
   * local and session storage.
   *
   * Persisting keeps the current legal entity in local and session storage and
   * key off of the user.We won't clear this on logout so the user can return to
   * their last viewed legal entity after logging back in.
   * @param {SessionLegalEntity} legalEntity The current legal entity to set
   * @return {void}
   */
  set currentLegalEntity(legalEntity: SessionLegalEntity) {
    if (!this.findLegalEntityById(legalEntity.id)) return

    this.sessionLegalEntity = legalEntity

    if (legalEntity.id !== this.sharingLegalEntity?.id) {
      this.persistCurrentLegalEntityId(legalEntity.id)
    }
  }

  /**
   * Get all active or approved legal entities in the user's JWT (across all of their orgs)
   *
   * @return Tuple of { legalEntity: SessionLegalEntity, organization: SessionOrganization }
   */
  get allActiveLegalEntities() {
    const legalEntities = this.organizations
      ?.flatMap((organization) =>
        organization.legalEntities.map((legalEntity) => ({ legalEntity, organization }))
      )
      .filter(defined)

    if (this.doppelganger?.hasDashboardAccess) return legalEntities

    return legalEntities.filter(({ legalEntity }) => legalEntity.isActive || legalEntity.isApproved)
  }

  /**
   * Determine the currently legal entity with fallbacks to the first dashboard accessible
   * legal entity.
   *
   * @return {SessionLegalEntity} The current legal entity
   */
  private findCurrentLegalEntity() {
    if (this.sessionLegalEntity) {
      const currentLE = this.findLegalEntityById(this.sessionLegalEntity.id)
      // if it is an employee return the LE in the preferences regardless of LE status
      if (currentLE) return currentLE
    }

    // Look up by current legal entity
    const lastLegalEntityId = this.lastKnownLegalEntityId

    if (lastLegalEntityId && lastLegalEntityId !== this.sharingLegalEntity?.id) {
      const lastKnownLE = this.findLegalEntityById(lastLegalEntityId)
      if (lastKnownLE) return lastKnownLE
    }

    return this.findFirstDashboardAccessLegalEntity() as SessionLegalEntity
  }

  /**
   * Clear the current legal entity from storage.
   * TODO: Remove this method in favor of a setter. Requires first allowing
   *  currentLegalEntity to be undefined.
   *
   * @return {void}
   */
  clearCurrentLegalEntity() {
    this.sessionLegalEntity = undefined
  }

  /**
   * Get the current affiliation.
   * @return {SessionAffiliation|undefined} The current affiliation.
   */
  get currentAffiliation(): SessionAffiliation | undefined {
    const { currentLegalEntity } = this

    return this.organizations
      .flatMap((org) => org.affiliations)
      .find((af) => af?.entity.id === currentLegalEntity?.id)
  }

  /**
   * Get the current legal entity id.
   *
   * NOTE: Until we support switching between Legal Entities, this is
   * always the first Legal Entity associated to the current organization
   * @return {string} The current legal entity id
   */
  get currentLegalEntityId() {
    const le = this.currentLegalEntity
    return le?.id
  }

  // Get the last known Legal Entity Id from the local storage
  get lastKnownLegalEntityId() {
    return (
      this.storage.session.getItem(FrontendSession.CURRENT_LE_ID_STORAGE_NAMESPACE) ??
      this.getGlobalPreference(FrontendSession.CURRENT_LE_ID_STORAGE_NAMESPACE)
    )
  }

  /**
   * Find a legal entity in the session by the id
   *
   * @param {string} id The id of the legal entity to check if valid
   * @return {SessionLegalEntity | undefined} The found legal entity or undefined if not found
   */
  findLegalEntityById(id: string) {
    const legalEntities = this.organizations
      .flatMap((organization) => organization.legalEntities.find((le) => le.id === id))
      .filter<SessionLegalEntity>((le): le is SessionLegalEntity => le !== undefined)

    const affiliatedEntities =
      legalEntities.length === 0 &&
      this.organizations
        .flatMap((organization) => organization.affiliatedLegalEntities.find((le) => le.id === id))
        .filter<SessionLegalEntity>((le): le is SessionLegalEntity => le !== undefined)

    return legalEntities.length
      ? legalEntities[0]
      : affiliatedEntities && affiliatedEntities.length
        ? affiliatedEntities[0]
        : undefined
  }

  /**
   * Finds an active or affiliated legal entity in the session _token_ data,
   * by the provided slug.
   *
   * This call exists to be able to answer whether the LE represented by the
   * provided slug is a natural part of this session, ignoring any current
   * sharing context.
   */
  findLegalEntityBySlugInToken(slug: string) {
    // sessionOrganizations are just those from the JWT token, kept separate
    // from the sharing context
    return this.findLegalEntityBySlugIn(this.sessionOrganizations, slug)
  }

  /**
   * Find a LE or affiliated LE in the session by the slug and optional status
   *
   * This is useful for when you only have a partial reference to a LE.
   * @param {string} slug The slug of the legal entity to check if valid
   * @param {LegalEntityStatus} status Optional status to search by
   * @return {SessionLegalEntity | undefined} The found legal entity or undefined if not found
   */
  findLegalEntityBySlug(slug: string, status?: LegalEntityStatus) {
    return this.findLegalEntityBySlugIn(this.organizations, slug, status)
  }

  /**
   * Find an affiliated LE in the session by the slug and optional status
   *
   * This is useful for when you only have a partial reference to a LE.
   * @param {string} slug The slug of the legal entity to check if valid
   * @param {LegalEntityStatus} status Optional status to search by
   * @return {SessionLegalEntity | undefined} The found legal entity or undefined if not found
   */
  findAffiliateLegalEntityBySlug(slug: string, status?: LegalEntityStatus) {
    return this.findAffiliateLegalEntityBySlugIn(this.organizations, slug, status)
  }

  findLegalEntityBySlugIn(
    organizations: SessionBusinessOrganization[],
    slug: string,
    status?: LegalEntityStatus
  ) {
    const legalEntities = organizations
      .flatMap((organization) =>
        organization.legalEntities.find(
          (le) => le.slug === slug && (!status || le.status === status)
        )
      )
      .filter<SessionLegalEntity>((le): le is SessionLegalEntity => le !== undefined)

    return legalEntities?.[0] || this.findAffiliateLegalEntityBySlugIn(organizations, slug, status)
  }

  findAffiliateLegalEntityBySlugIn(
    organizations: SessionBusinessOrganization[],
    slug: string,
    status?: LegalEntityStatus
  ) {
    const affiliatedEntities = organizations
      .filter((org) => !!org.affiliations)
      .flatMap((organization) =>
        organization.affiliatedLegalEntities.find(
          (le) => le.slug === slug && (!status || le.status === status)
        )
      )
      .filter<SessionLegalEntity>((le): le is SessionLegalEntity => le !== undefined)

    return affiliatedEntities[0]
  }

  /**
   * Find an **active** or **approved** legal entity (or affiliate LE) in the session by the slug
   *
   * This is useful for when you only have a partial reference to a LE.
   * @param {string} slug The slug of the legal entity to check if valid
   * @return {SessionLegalEntity | undefined} The found legal entity or undefined if not found
   */
  findDashboardLegalEntityBySlug(slug: string) {
    return (
      this.findLegalEntityBySlug(slug, LegalEntityStatus.Active) ||
      this.findLegalEntityBySlug(slug, LegalEntityStatus.Approved)
    )
  }

  /**
   * Find a **pending** affiliate legal entity in the session by the slug
   * For now, Pending & PendingHold should be considered the same status.
   *
   * This is useful for when you only have a partial reference to a LE.
   * @param {string} slug The slug of the legal entity to check if valid
   * @return {SessionLegalEntity | undefined} The found legal entity or undefined if not found
   */
  findOnboardingAffiliateLegalEntityBySlug(slug: string) {
    let pending = this.findAffiliateLegalEntityBySlug(slug, LegalEntityStatus.Pending)
    if (pending) return pending

    pending = this.findAffiliateLegalEntityBySlug(slug, LegalEntityStatus.PendingHold)
    if (pending) return pending

    return this.findAffiliateLegalEntityBySlug(slug, LegalEntityStatus.New)
  }

  findFirstOnboardingLegalEntity() {
    const org =
      this.organizations.find((o) => o.hasPendingLegalEntity) ||
      this.organizations.find((o) => o.hasPendingAffiliations)
    return org?.pendingLegalEntities?.[0] || org?.pendingAffiliations?.[0]?.entity
  }

  /**
   * Find first legal entity with dashboard access. This intentionally excludes sharing legal
   * entities. Due to their ephemeral nature (not present in session JWT), we don't want them as
   * a fallback.
   *
   * @return {SessionLegalEntity | undefined} The found legal entity or undefined if not found
   */
  findFirstDashboardAccessLegalEntity() {
    // Look up by current legal entity
    const lastLegalEntityId = this.lastKnownLegalEntityId
    if (lastLegalEntityId && lastLegalEntityId !== this.sharingLegalEntity?.id) {
      const lastKnownLE = this.findLegalEntityById(lastLegalEntityId)
      if (lastKnownLE?.hasDashboardAccess(this.doppelganger)) return lastKnownLE
    }

    const firstActiveAffiliation =
      this.findFirstDashboardAccessAffiliationOrganization()?.affiliatedLegalEntities.find(
        (le) =>
          le.hasDashboardAccess(this.doppelganger) &&
          le.isActive &&
          le.id !== this.sharingLegalEntity?.id
      )

    const firstActiveLE = this.findFirstDashboardAccessOrganization()?.legalEntities.find(
      (le) =>
        le.hasDashboardAccess(this.doppelganger) &&
        le.isActive &&
        le.id !== this.sharingLegalEntity?.id
    )

    const firstAffiliation =
      this.findFirstDashboardAccessAffiliationOrganization()?.affiliatedLegalEntities.find(
        (le) => le.hasDashboardAccess(this.doppelganger) && le.id !== this.sharingLegalEntity?.id
      )

    const firstLE = this.findFirstDashboardAccessOrganization()?.legalEntities.find(
      (le) => le.hasDashboardAccess(this.doppelganger) && le.id !== this.sharingLegalEntity?.id
    )

    // Prefer active LEs over pending LEs
    return firstActiveAffiliation || firstActiveLE || firstAffiliation || firstLE
  }

  /**
   * Find first organization with dashboard access. Prefer organizations with dashboard access
   * over organizations with pending legal entities but user has a DG token.
   *
   * @return {SessionLegalEntity | undefined} The found legal entity or undefined if not found
   */
  findFirstDashboardAccessOrganization() {
    const { doppelganger } = this
    // Look up the current org id stored in user preferences. Performing this here since
    // we'll only want to use this as a fallback if we don't know where else to send the user.
    const currentOrgId = this.lastKnownOrganizationId

    // If we find a stored current organization, look for in the available organizations
    if (currentOrgId) {
      const currentOrg = this.findOrganizationById(currentOrgId)

      if (
        currentOrg?.hasDashboardLegalEntity ||
        (currentOrg?.legalEntities.length && doppelganger?.hasDashboardAccess)
      ) {
        return currentOrg
      }
    }

    // Loop over session organizations and identify organizations with dashboard access
    // or any with at least 1 legal entity if the user is a DGer with dashboard access. Prefer
    // the former if found.
    //
    // NOTE: Using session organization intentionally as we don't want to consider shared organizations
    // for this purpose (fallback logic should only consider access present on the JWT)
    let dashboardAccess: SessionBusinessOrganization | undefined
    let dgAccess: SessionBusinessOrganization | undefined
    this.sessionOrganizations.forEach((o) => {
      if (!dashboardAccess && o.hasDashboardLegalEntity) dashboardAccess = o
      if (!dgAccess && o.legalEntities.length && doppelganger?.hasDashboardAccess) dgAccess = o
    })

    return dashboardAccess || dgAccess
  }

  /**
   * Find first affiliation organization with dashboard access. Prefer organizations with
   * dashboard access over organizations with pending legal entities but user has a DG token.
   *
   * @return {SessionLegalEntity | undefined} The found legal entity or undefined if not found
   */
  findFirstDashboardAccessAffiliationOrganization() {
    const { doppelganger } = this

    // Look up the current org id stored in user preferences. Performing this here since
    // we'll only want to use this as a fallback if we don't know where else to send the user.
    const currentOrgId = this.lastKnownOrganizationId

    // If we find a stored current organization, look for in the available organizations
    if (currentOrgId) {
      const dashboardOrg = this.findOrganizationById(currentOrgId)
      if (dashboardOrg?.hasDashboardAffiliations) return dashboardOrg
    }

    // Loop over session organizations and identify affiliation organizations with dashboard access
    // or any with at least 1 legal entity if the user is a DGer with dashboard access. Prefer
    // the former if found.
    //
    // NOTE: Using session organization intentionally as we don't want to consider shared organizations
    // for this purpose (fallback logic should only consider access present on the JWT)
    let dashboardAccess: SessionBusinessOrganization | undefined
    let dgAccess: SessionBusinessOrganization | undefined
    this.sessionOrganizations.forEach((o) => {
      if (!dashboardAccess && o.hasDashboardAffiliations) dashboardAccess = o
      if (!dgAccess && o.hasAffiliations && doppelganger?.hasDashboardAccess) dgAccess = o
    })

    return dashboardAccess || dgAccess
  }

  /*
    AFFILIATIONS
  */

  /**
   * Check if any of the organizations on the session have affiliated legal entities.
   * @return {boolean} If any organizations have an `Active` Legal Entity
   */
  get hasAffiliations() {
    return this.organizations.some((organization) => organization.hasAffiliations)
  }

  /**
   * Check if the current session is an affiliated user checking an affiliated organization.
   * In this case, both {@link currentAffiliatedOrganization} and {@link currentOrganization} are set.
   * @return {boolean} If current session is an affiliated session
   */
  get isAffiliatedSession() {
    return !!(this.currentAffiliatedOrganization && this.currentOrganization)
  }

  /**
   * Check if the current session is an affiliated user that has not activated their employment. Will
   * not be true if a DG-er or digits employee.
   * @return {boolean} If current session is affiliated and no activated
   */
  get isAffiliateNotActivated(): boolean {
    const isEmploymentNotActivated =
      !!this.currentOrganization && !this.currentOrganization.isEmploymentActive

    return (
      isEmploymentNotActivated &&
      this.isAffiliatedSession &&
      !this.isDigitsEmployee &&
      !this.isDoppelganger &&
      this.currentLegalEntity.isAffiliated
    )
  }

  /**
   * ASPECTS
   */

  hasAccessToAspect(aspect?: AspectCode) {
    return this.currentLegalEntity?.hasAccessToAspect(aspect) || !!this.doppelganger?.hasFullAccess
  }

  hasAnyLegalEntityWithAccessToAspect(aspect?: AspectCode) {
    return (
      (!!aspect &&
        this.organizations
          .flatMap((o) => o.affiliatedLegalEntities)
          .some((le) => le.hasAccessToAspect(aspect))) ||
      this.isDigitsEmployee
    )
  }

  /**
   * TERMS OF SERVICE ACCEPTANCE
   */

  /**
   * Check if the the current version of TOS has been accepted
   *
   * @return If the the current version of TOS has been accepted
   */
  get hasAcceptedLatestTOS() {
    // If we're currently a visitor in a sharing context, we're not a member of the
    // current legal entity. Since we can't accept their terms anyway, short-circuit
    // the check.
    return this.isSharingContextActive || hasAcceptedTOS(this.termsOfServiceVersion, this.user)
  }

  get termsOfServiceVersion() {
    return TOS_VERSION.trim()
  }

  /*
    ONBOARDING
  */

  /**
   * Check if the session is properly registered
   *
   * @return If the session is registered
   */
  get isRegistered() {
    return !!this.user?.id && !!this.user?.emailAddress
  }

  /**
   * Check if there are any legal entities on the session
   *
   * @return {boolean} If the session has any legal entities
   */
  get hasLegalEntity() {
    return this.organizations.some((o) => !!o.legalEntities.length)
  }

  /**
   * Check if any of the organizations on the session has any Legal Entities
   * that are affiliated.
   *
   *
   * @return {boolean} If any organizations have an `Approved` Legal Entity
   */
  get hasDashboardAffiliations() {
    return this.organizations.some((o) => o.hasDashboardAffiliations)
  }

  /**
   * Check if any of the organizations on the session has any Legal Entities
   * that are `Pending`.
   *
   * @return {boolean} If any organizations have an `Active` Legal Entity
   */
  get hasPendingLegalEntities() {
    return (
      this.organizations.some((o) => o.hasPendingLegalEntity) ||
      this.organizations.some((o) => o.hasPendingAffiliations)
    )
  }

  /**
   * Check if any of the organizations on the session has any Legal Entities
   * that are `Active`.
   *
   * In order to see the dashboard, at least 1 Organization has to have an active
   * Legal Entity. Allow Dopplegangers to see customers dashboards regardless
   * of the Legal Entity's status, so we can preview what a customer would see.
   *
   * @return {boolean} If any organizations have an `Active` Legal Entity
   */
  get hasActiveLegalEntity() {
    return this.organizations.some((o) => o.hasActiveLegalEntity)
  }

  /**
   * Check if any of the organizations on the session has any Legal Entities
   * that have dashboard access or is affiliated with a Legal Entities that has dashboard access.
   *
   * @return {boolean} If any organizations have an `Active` Legal Entity
   */
  get hasDashboardLegalEntityOrAffiliation() {
    return (
      this.organizations.some(
        (o) =>
          o.hasDashboardLegalEntity ||
          (o.legalEntities.length && this.doppelganger?.hasDashboardAccess)
      ) ||
      this.organizations.some(
        (o) =>
          o.hasDashboardAffiliations ||
          (o.affiliatedLegalEntities.length && this.doppelganger?.hasDashboardAccess)
      )
    )
  }

  /*
    INTERNAL
  */

  /**
   * Return primary experience for the organizational employment
   */
  get currentPrimaryExperience() {
    if (this.currentAffiliation) {
      return this.currentAffiliation.primaryExperience
    }

    return this.currentOrganization?.primaryExperience
  }

  /**
   * Decodes the JWT into its stored data. This includes the user, when
   * the session expires, the organization, and it's legal entities.
   */
  protected decodeSession() {
    super.decodeSession()
    const session = this.decodedUnsignedJWT

    // If the session belongs to organizations, decode and set them
    const employments = session?.user?.memberships || []
    this.sessionOrganizations = employments.reduce(this.decodeSessionEmployments.bind(this), [])

    return undefined
  }

  protected setFromStorage() {
    const currentOrgId = this.getGlobalPreference(FrontendSession.CURRENT_ORG_ID_STORAGE_NAMESPACE)
    const currentLEId = this.getGlobalPreference(FrontendSession.CURRENT_LE_ID_STORAGE_NAMESPACE)

    if (currentOrgId) {
      this.storage.session.setItem(FrontendSession.CURRENT_ORG_ID_STORAGE_NAMESPACE, currentOrgId)
    }
    if (currentLEId) {
      this.storage.session.setItem(FrontendSession.CURRENT_LE_ID_STORAGE_NAMESPACE, currentLEId)
    }
  }

  /**
   * Used when parsing a JWT session. Decodes a JWTEmployment
   * into a SessionOrganization and stores it on a passed array of
   * SessionOrganizations.
   */
  private decodeSessionEmployments(
    organizations: SessionBusinessOrganization[],
    employment: JWTEmployment
  ) {
    const { id: employmentId, org, perms, a: employmentActivated, priexp } = employment

    const { id, name, slug, icon, entities, affiliations: rawAffiliations } = org
    const legalEntities = entities
      ? entities.map<SessionLegalEntity>((e) => this.decodeLegalEntity(id, e))
      : []

    const affiliations = rawAffiliations
      ? rawAffiliations.map<SessionAffiliation>((af) => this.decodeAffiliation(id, af))
      : undefined

    const permissions = new SessionPermissions(perms)
    const priExpKey = objectHelper.keysOf(Experience).find((k) => Experience[k] === priexp)
    const primaryExperience = priExpKey ? Experience[priExpKey] : undefined

    const organization: SessionBusinessOrganizationAttributes = {
      id,
      name,
      slug,
      iconUrl: AssetsHelper.publicUrl(CDNAsset.OrganizationIcons, icon),
      legalEntities,
      affiliations,
      permissions,
      employmentId,
      employmentActivated,
      primaryExperience,
    }
    organizations.push(new SessionBusinessOrganization(organization))

    return organizations
  }

  private decodeLegalEntity(
    organizationId: string,
    rawLegalEntity: JWTLegalEntity,
    affiliationId?: string
  ) {
    const modifiedRawLegalEntity = {
      ...rawLegalEntity,
    }

    return new SessionLegalEntity(organizationId, modifiedRawLegalEntity, affiliationId)
  }

  private decodeAffiliation(organizationId: string, rawAffiliation: JWTAffiliation) {
    const {
      entity: rawEntity,
      org: rawOrganization,
      perms,
      priexp,
      ...rawAttributes
    } = rawAffiliation

    const entity = this.decodeLegalEntity(organizationId, rawEntity, rawAttributes.id)

    const organization = new SessionAffiliationOrganization({
      ...(rawOrganization || {}),
      iconUrl: AssetsHelper.publicUrl(CDNAsset.OrganizationIcons, rawOrganization.icon),
    })

    const permissions = new SessionPermissions<FrontendPermissionModule>(perms)

    const priExpKey = objectHelper.keysOf(Experience).find((k) => Experience[k] === priexp)
    const primaryExperience = priExpKey ? Experience[priExpKey] : undefined

    const attributes = {
      ...rawAttributes,
      entity,
      organization,
      permissions,
      primaryExperience,
    }
    return new SessionAffiliation(attributes)
  }

  private persistCurrentOrganizationId(organizationId: string | undefined) {
    if (!organizationId) {
      this.deleteGlobalPreference(FrontendSession.CURRENT_ORG_ID_STORAGE_NAMESPACE)
      this.storage.session.removeItem(FrontendSession.CURRENT_ORG_ID_STORAGE_NAMESPACE)
    }

    // Set organization id, reset legal entity until it is set by directly
    if (organizationId) {
      this.setGlobalPreference(FrontendSession.CURRENT_ORG_ID_STORAGE_NAMESPACE, organizationId)
      this.storage.session.setItem(FrontendSession.CURRENT_ORG_ID_STORAGE_NAMESPACE, organizationId)

      // delete the stored legalEntity to avoid conflicts
      this.deleteGlobalPreference(FrontendSession.CURRENT_LE_ID_STORAGE_NAMESPACE)
      this.storage.session.removeItem(FrontendSession.CURRENT_LE_ID_STORAGE_NAMESPACE)
    }
  }

  private persistCurrentLegalEntityId(legalEntityId: string | undefined) {
    if (!legalEntityId) {
      this.deleteGlobalPreference(FrontendSession.CURRENT_LE_ID_STORAGE_NAMESPACE)
      this.storage.session.removeItem(FrontendSession.CURRENT_LE_ID_STORAGE_NAMESPACE)
    }

    // Set legal entity id, and find its org and set the org id to keep it in sync
    if (legalEntityId) {
      this.setGlobalPreference(FrontendSession.CURRENT_LE_ID_STORAGE_NAMESPACE, legalEntityId)
      this.storage.session.setItem(FrontendSession.CURRENT_LE_ID_STORAGE_NAMESPACE, legalEntityId)
      // find the legal entities org to sync org->le relationship
      const leOrg = this.findOrganizationByLegalEntityId(legalEntityId)
      if (!leOrg) {
        console.error(
          "current LE id does not match any current org",
          legalEntityId,
          this.organizations
        )
        return
      }

      this.setGlobalPreference(FrontendSession.CURRENT_ORG_ID_STORAGE_NAMESPACE, leOrg.id)
      this.storage.session.setItem(FrontendSession.CURRENT_ORG_ID_STORAGE_NAMESPACE, leOrg.id)
    }
  }
}

// Memoized to avoid re-checking dates multiple times per render
const hasAcceptedTOS = memoizeOne(
  (termsOfServiceVersion: string, tosSource: SessionUser | SessionLegalEntity | undefined) => {
    if (!tosSource) return true

    const { termsAcceptedAt } = tosSource
    if (!termsAcceptedAt) return false

    const tosAcceptance = moment(termsAcceptedAt)
    const version = moment(termsOfServiceVersion, "YYYY-MM-DD")
    if (!tosAcceptance.isValid() || !version.isValid()) {
      TrackJS?.console.error("invalid dates for TOS check", tosAcceptance, version)
      return false
    }

    return tosAcceptance.isAfter(version) || tosAcceptance.isSame(version)
  }
)
