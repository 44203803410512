import * as React from "react"
import { ObjectEntities } from "@digits-graphql/frontend/graphql-bearer"
import styled from "styled-components"
import { useAssistantObjectIdentifier } from "src/frontend/components/Shared/Assistant/hooks/useAssistantObjectIdentifier"
import { useLayoutContext } from "src/frontend/components/Shared/Assistant/Messages/Layout/Context"
import { useTextComponentData } from "src/frontend/components/Shared/Assistant/Messages/Layout/hooks/useTextComponent"
import { MatchedComponent } from "src/frontend/components/Shared/Layout/types"
import { useEntityPopOver } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/Components/Text/PopOver/EntityPopOver"
import { EditableContent } from "src/shared/components/EditableContent/EditableContent"
import EntitiesParser from "src/shared/components/ObjectEntities/EntitiesParser"
import { EntityHover } from "src/shared/components/ObjectEntities/entityPopOverTypes"

/*
  STYLES
*/

const TextContainer = styled.div`
  position: relative;

  &:hover div {
    opacity: 1;
  }
`

const EditableComponent = styled(EditableContent)`
  padding: 0;
  color: inherit;
  font-weight: inherit;
`

/*
  INTERFACES
*/

type Props = {
  component: MatchedComponent<"text">
  toolbarPortalElId: string | undefined
}

/*
  COMPONENTS
*/

export const TextComponent: React.FC<Props> = ({ component, toolbarPortalElId }) => {
  const { layoutId, viewKey } = useLayoutContext()
  const {
    config: {
      text: { body },
    },
    dataId,
  } = component

  const { data } = useTextComponentData(layoutId, viewKey, dataId)
  const hovers: EntityHover[] = React.useMemo(() => {
    const entityHovers: EntityHover[] = []
    data?.hovers.forEach((hover) => {
      if (hover.__typename === "CategoryHover" || hover.__typename === "PartyHover") {
        entityHovers.push(hover)
      }
    })
    return entityHovers
  }, [data?.hovers])

  const entities = React.useMemo(() => {
    const objEntities: ObjectEntities = {
      parties: [],
      categories: [],
      users: [],
    }

    hovers
      ?.map((hover) => hover.entity)
      .forEach((entity) => {
        switch (entity.__typename) {
          case "EntityCategory":
            objEntities.categories?.push(entity)
            break
          case "EntityParty":
            objEntities.parties?.push(entity)
            break
        }
      })

    return objEntities
  }, [hovers])

  const entityPopOver = useEntityPopOver(hovers)

  const identifier = useAssistantObjectIdentifier()

  return (
    <TextContainer>
      <EditableComponent
        disabled={false}
        identifier={identifier}
        readonly
        autocomplete={[]}
        toolbarPortalElId={toolbarPortalElId}
      >
        <EntitiesParser
          text={body}
          entities={entities}
          editMode={false}
          entityPopOver={entityPopOver}
          decode
        />
      </EditableComponent>
    </TextContainer>
  )
}
