import * as React from "react"
import { Route, Switch } from "react-router-dom"
import { ModuleLoading } from "@digits-shared/components/Loaders"
import { LoggedRedirect } from "@digits-shared/components/Router/LoggedRedirect"
import { ProtectedRoute } from "@digits-shared/components/Router/ProtectedRoute"
import { isMobile } from "@digits-shared/helpers/devicesHelper"
import useConstant from "@digits-shared/hooks/useConstant"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import { AspectCode } from "@digits-shared/session/SessionTypes"
import { WorkItemQueryParam } from "src/frontend/components/OS/Accountant/Applications/Boost/WorkItems/hooks/boostTypes"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import FrontendSession from "src/frontend/session"

const ReportsApplication = React.lazy(
  () => import("src/frontend/components/OS/Springboard/Applications/Reports/ReportsApplication")
)

const AnalysisApplication = React.lazy(
  () => import("src/frontend/components/OS/Springboard/Applications/Analysis/AnalysisApplication")
)

const BillPayApplication = React.lazy(
  () => import("src/frontend/components/OS/Springboard/Applications/BillPay/BillPayApplication")
)

const InvoicingApplication = React.lazy(
  () => import("src/frontend/components/OS/Springboard/Applications/Invoicing/InvoicingApplication")
)

const IdentityVerification = React.lazy(
  () =>
    import(
      "src/frontend/components/OS/Springboard/Applications/IdentityVerification/IdentityVerification"
    )
)

const GetPaidApplication = React.lazy(
  () => import("src/frontend/components/OS/Springboard/Applications/GetPaid/GetPaidApplication")
)

const AssistantCenter = React.lazy(() =>
  import("src/frontend/components/Shared/Assistant/Assistant").then((m) => ({
    default: m.AssistantCenter,
  }))
)

const AccountingApplication = React.lazy(
  () =>
    import("src/frontend/components/OS/Springboard/Applications/Accounting/AccountingApplication")
)

const ClientActionItemsRedirect = React.lazy(
  () =>
    import(
      "src/frontend/components/OS/Springboard/Applications/ClientTransactionReview/Shared/ActionItemsRedirect"
    )
)

const ClientTransactionReviewMobile = React.lazy(
  () =>
    import(
      "src/frontend/components/OS/Springboard/Applications/ClientTransactionReview/ContentView/ClientTransactionReview.mobile"
    )
)

const VaultApplication = React.lazy(
  () => import("src/frontend/components/OS/Springboard/Applications/Vault/VaultApplication")
)

const OperatorApplications: React.FC = () => {
  const session = useSession<FrontendSession>()
  const generatePath = useFrontendPathGenerator()
  const objectSharingPaths = useConstant(() => routes.objectSharingPaths())
  const viewVersion = useViewVersion()
  const leParams = { leSlug: session.currentLegalEntity?.slug }
  const { location } = useRouter()

  return (
    <React.Suspense fallback={<ModuleLoading />}>
      <Switch>
        {/* Analysis */}
        <Route
          path={routes.analysis.parameterizedPath}
          render={() => (
            <ProtectedRoute
              redirectTo={generatePath(routes.dashboard)}
              component={AnalysisApplication}
              requiresView={viewVersion}
            />
          )}
        />

        {/* Reports */}
        <Route
          path={routes.reports.parameterizedPath}
          render={() => (
            <ProtectedRoute
              redirectTo={generatePath(routes.dashboard)}
              component={ReportsApplication}
              requiresView={viewVersion}
            />
          )}
        />

        {/* Sharing Details Route Handler */}
        <Route path={objectSharingPaths} component={SharingDetails} />

        {/* Bill Pay */}
        <Route
          path={routes.oldPayBills.parameterizedPath}
          render={() => (
            <LoggedRedirect
              name="AccountantApplications-RedirectOldPayBills"
              to={generatePath(routes.billPay, { leSlug: session.currentLegalEntity?.slug })}
            />
          )}
        />
        <Route
          path={[
            routes.billPay.parameterizedPath,
            routes.billDetails.parameterizedPath,
            routes.billPaymentDetails.parameterizedPath,
          ]}
          render={() => (
            <ProtectedRoute
              aspectCode={AspectCode.PayBills}
              redirectTo={generatePath(routes.dashboard)}
              component={BillPayApplication}
            />
          )}
        />

        <Route
          path={routes.legalEntityIdentityVerification.parameterizedPath}
          render={() => (
            <ProtectedRoute
              aspectCode={AspectCode.PayBills}
              redirectTo={generatePath(routes.dashboard)}
              component={IdentityVerification}
            />
          )}
        />

        {/* Invoicing */}
        <Route
          path={[routes.invoicing.parameterizedPath, routes.invoice.parameterizedPath]}
          render={() => (
            <ProtectedRoute
              aspectCode={AspectCode.Invoicing}
              redirectTo={generatePath(routes.dashboard)}
              component={InvoicingApplication}
            />
          )}
        />

        {/* Get Paid */}
        <Route
          path={routes.getPaid.parameterizedPath}
          render={() => (
            <ProtectedRoute
              aspectCode={AspectCode.GetPaid}
              redirectTo={generatePath(routes.dashboard)}
              component={GetPaidApplication}
            />
          )}
        />

        {/* Assistant */}
        <Route
          path={routes.assistant.parameterizedPath}
          render={() => (
            <ProtectedRoute
              digitsEmployeeOnly
              redirectTo={generatePath(routes.dashboard)}
              component={AssistantCenter}
            />
          )}
        />

        {/* Accounting Dashboard */}
        <Route
          path={[routes.accounting.parameterizedPath, routes.categoryReview.parameterizedPath]}
          render={() => (
            <ProtectedRoute
              redirectTo={generatePath(routes.dashboard)}
              component={AccountingApplication}
              requiresView={viewVersion}
              aspectCode={AspectCode.AIBookkeeper}
            />
          )}
        />

        {/* Client Transaction Review */}
        {/* only mobile/tablet renders a Content view */}
        {isMobile && (
          <Route
            path={routes.clientTransactionReview.parameterizedPath}
            component={ClientTransactionReviewMobile}
          />
        )}

        {/* redirect from /action-items-list and /action-items to /action-items-list/transaction-review */}
        {/* redirect from /action-items/:workItemId to /action-items-list/transaction-review/:workItemId */}
        <Route
          path={[
            routes.actionItemsList.parameterizedPath,
            routes.actionItems.parameterizedPath,
            routes.actionItem.parameterizedPath,
          ]}
          component={ClientActionItemsRedirect}
          exact
        />

        {/* Vault */}
        <Route
          path={routes.vault.parameterizedPath}
          render={() => (
            <ProtectedRoute
              redirectTo={generatePath(routes.dashboard)}
              component={VaultApplication}
              requiresView={viewVersion}
              aspectCode={AspectCode.Vault}
            />
          )}
        />

        {/* Fallback redirects for legacy routes */}

        <Route
          path={routes.clientPortal.parameterizedPath}
          component={LegacyClientPortalRedirect}
        />

        <Route
          path={routes.burn.parameterizedPath}
          render={() => (
            <LoggedRedirect
              name="OperatorApplications-LegacyBurnRedirect"
              to={generatePath(routes.expenses, leParams)}
            />
          )}
        />

        <Route
          path={routes.sales.parameterizedPath}
          render={() => (
            <LoggedRedirect
              name="OperatorApplications-LegacySalesRedirect"
              to={generatePath(routes.revenue, leParams)}
            />
          )}
        />

        {/* if an operator navigates here from a notification with a taskId, redirect to the client view */}
        <Route
          path={routes.transactionReview.parameterizedPath}
          render={() => (
            <LoggedRedirect
              name="OperatorApplications-TransactionReviewRedirect"
              to={
                location.queryParams[WorkItemQueryParam.TaskId] || undefined
                  ? generatePath(routes.actionItem, {
                      leSlug: session.currentLegalEntity?.slug,
                      workItemId: location.queryParams[WorkItemQueryParam.TaskId],
                    })
                  : generatePath(routes.root)
              }
            />
          )}
        />

        <Route
          path="*"
          render={() => (
            <LoggedRedirect
              name="OperatorApplications-fallThrough"
              to={generatePath(routes.root)}
            />
          )}
        />
      </Switch>
    </React.Suspense>
  )
}

export default OperatorApplications

const SharingDetails: React.FC = () => {
  const generatePath = useFrontendPathGenerator()
  const { isSharingContextActive, currentLegalEntity } = useSession<FrontendSession>()
  const viewVersion = useViewVersion()

  if (isSharingContextActive) {
    return (
      <LoggedRedirect
        name="SharingDetails-sharingContextActive"
        to={generatePath(routes.sharedWithMe)}
      />
    )
  }

  return (
    <Switch>
      <Route
        path={routes.reportPackage.parameterizedPath}
        render={() => (
          <ProtectedRoute
            redirectTo={generatePath(routes.dashboard)}
            component={ReportsApplication}
          />
        )}
      />
      <Route
        path={[routes.accounting.parameterizedPath, routes.categoryReview.parameterizedPath]}
        render={() => (
          <ProtectedRoute
            redirectTo={generatePath(routes.dashboard)}
            component={AccountingApplication}
            requiresView={viewVersion}
            aspectCode={AspectCode.AIBookkeeper}
          />
        )}
      />

      <Route
        path="*"
        render={() => (
          <LoggedRedirect
            name="SharingDetails-fallThrough"
            to={generatePath(routes.legalEntityHome, {
              leSlug: currentLegalEntity?.slug,
            })}
          />
        )}
      />
    </Switch>
  )
}

const LegacyClientPortalRedirect: React.FC = () => {
  const { location } = useRouter()
  const newLocation = location.fullPathname.replace("/portal", "")
  return <LoggedRedirect name="OperatorApplications-LegacyClientportal" to={newLocation} />
}
