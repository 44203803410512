import React from "react"

export const usePropagateHookToAtom = <HookValue, TransformedResult = HookValue>(
  updateAtom: (value: HookValue | TransformedResult) => void,
  useValueHook: () => HookValue,
  transformer?: (value: HookValue) => TransformedResult
) => {
  const hookValue = useValueHook()

  React.useEffect(() => {
    if (transformer) {
      updateAtom(transformer(hookValue))
    } else {
      updateAtom(hookValue)
    }
  }, [updateAtom, transformer, hookValue])
}
