import * as React from "react"
import { GRAPHQL_TRACE_KEY } from "@digits-shared/components/Contexts/GraphQLTracerContext"
import useSession from "@digits-shared/hooks/useSession"
import FrontendSession from "src/frontend/session"
import { userPreferenceKeys } from "src/shared/config/userPreferenceKeys"

export function useInternalUserSettings() {
  const session = useSession<FrontendSession>()

  const toggleUserSetting = React.useCallback(
    (setting: string, enabled?: boolean) => {
      if (enabled === undefined) {
        return session.deleteUserPreference(setting)
      }
      session.setUserPreference(setting, enabled)
    },
    [session]
  )

  const graphqlTracing = session.getBooleanUserPreference(GRAPHQL_TRACE_KEY)

  /**
   * Use In-memory store for mock data in Invoicing
   */
  const mockInvoicingDataStore = session.getBooleanUserPreference(
    userPreferenceKeys.mockInvoicingDataStore
  )

  /**
   * Use Mock data for Invoicing
   */
  const mockInvoicingData = session.getBooleanUserPreference(userPreferenceKeys.mockInvoicingData)

  /**
   * Check if the new action items dashboard should be displayed
   */
  const newViewVersionAlertEnabled = session.getBooleanUserPreference(
    userPreferenceKeys.newViewVersionAlert
  )

  /**
   * Check if the grid overlay should be displayed
   */
  const gridOverlayEnabled = session.getBooleanUserPreference(userPreferenceKeys.gridDevTools)

  /**
   * Check if the animation config dev tools preference is enabled.
   */
  const animationConfigDevToolsEnabled = session.getBooleanUserPreference(
    userPreferenceKeys.animationConfigDevTools
  )

  /**
   * Is vendor directory application exposed
   */
  const vendorDirectoryEnabled = session.getBooleanUserPreference(
    userPreferenceKeys.vendorDirectory
  )

  return React.useMemo(
    () => ({
      graphqlTracing,
      mockInvoicingDataStore,
      mockInvoicingData,
      newViewVersionAlertEnabled,
      gridOverlayEnabled,
      animationConfigDevToolsEnabled,
      vendorDirectoryEnabled,
      toggleUserSetting,
    }),
    [
      animationConfigDevToolsEnabled,
      graphqlTracing,
      gridOverlayEnabled,
      mockInvoicingData,
      mockInvoicingDataStore,
      newViewVersionAlertEnabled,
      toggleUserSetting,
      vendorDirectoryEnabled,
    ]
  )
}
