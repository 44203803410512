import * as React from "react"
import { Statement as StatementGQL } from "@digits-graphql/frontend/graphql-bearer"
import { LoadingCell } from "@digits-shared/components/UI/Table"
import colorHelper from "@digits-shared/helpers/colorHelper"
import useForwardedRef from "@digits-shared/hooks/useForwardedRef"
import { useMeasure } from "@digits-shared/hooks/useMeasure"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import {
  RowStyled,
  STATEMENT_COLUMN_WIDTH,
  STATEMENT_ROWS_CLASSNAME,
  StatementSize,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/shared"
import { StatementContext } from "src/frontend/components/Shared/Reports/Report/Components/Statements/StatementContext"
import { StatementHeader } from "src/frontend/components/Shared/Reports/Report/Components/Statements/StatementHeader"
import { StatementRowComponent } from "src/frontend/components/Shared/Reports/Report/Components/Statements/StatementRow"
import {
  normalizeRowId,
  useCollapsedRowFiltering,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/useCollapsedRowFiltering"
import { useRowCellSizes } from "src/frontend/components/Shared/Reports/Report/Components/Statements/useRowCellSizes"
import {
  useReportDocumentOptions,
  useReportExtraColumnsCount,
  useStatementBreakdownColumnsCount,
} from "src/frontend/components/Shared/Reports/Report/hooks/useReportDocumentOptions"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

const WIDE_CONTAINER_SIZE = 500

/*
  STYLES
*/

const Container = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  container-type: inline-size;
`

const StatementRows = styled.div<{
  $trendlineWidth: string | number
  $maxTitleCellWidth: string | number
  $maxTotalCellWidth: number
  $totalColumns: number
}>`
  position: relative;
  min-width: min(400px, 100%);

  ${({ $maxTitleCellWidth, $maxTotalCellWidth }) => css`
    --statement-cell-width: ${$maxTotalCellWidth}px;
    --statement-title-width: 1fr;

    @container (min-width: ${WIDE_CONTAINER_SIZE}px) {
      & {
        [orientation="portrait"] & {
          --statement-title-width: minmax(120px, ${$maxTitleCellWidth}px);
        }

        [orientation="landscape"] & {
          --statement-title-width: ${typeof $maxTitleCellWidth === "string"
            ? $maxTitleCellWidth
            : `minmax(${$maxTitleCellWidth}px, 1fr)`};
        }

        overflow-y: visible;
        overflow-x: scroll;

        // padding is for the columns crosshair, removing the spacing via negative margin
        padding: 8px 0 8px 20px;
        margin: -8px 0 -8px -20px;

        --statement-scroll-shadow-start: ${colorHelper.hexToRgba(colors.primary, 0)};
        --statement-scroll-shadow-end: ${colorHelper.hexToRgba(colors.primary, 0)};
        background:
            /* Shadow LEFT */
          radial-gradient(
              farthest-side at 0 50%,
              var(--statement-scroll-shadow-start),
              ${colorHelper.hexToRgba(colors.primary, 0)}
            )
            center left no-repeat scroll,
          /* Shadow RIGHT */
            radial-gradient(
              farthest-side at 100% 50%,
              var(--statement-scroll-shadow-end),
              ${colorHelper.hexToRgba(colors.primary, 0)}
            )
            center right no-repeat scroll;
        background-size:
          14px 100%,
          14px 100%;

        &.scroll-start {
          --statement-scroll-shadow-start: ${colorHelper.hexToRgba(colors.primary, 0.2)};
        }
        &.scroll-end {
          --statement-scroll-shadow-end: ${colorHelper.hexToRgba(colors.primary, 0.2)};
        }
      }
    }
  `};

  ${({ $trendlineWidth, $totalColumns }) => css`
    --statement-total-columns: ${$totalColumns};
    --statement-grid-template: var(--statement-title-width)
      ${typeof $trendlineWidth === "string" ? $trendlineWidth : `${$trendlineWidth}px`}
      repeat(var(--statement-total-columns), var(--statement-cell-width));
  `};
`

export const StatementLoadingRows = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

/*
  INTERFACE
*/

interface StatementProps {
  statement?: StatementGQL
  loading: boolean
  size?: StatementSize
}

/*
  COMPONENTS
*/

export const Statement: React.FC<StatementProps> = ({ statement, size, loading }) => {
  const statementContext = React.useMemo(
    () => ({
      kind: statement?.kind,
      size: size,
    }),
    [size, statement?.kind]
  )

  return (
    <StatementContext.Provider value={statementContext}>
      <StatementWithContext statement={statement} loading={loading} size={size} />
    </StatementContext.Provider>
  )
}

const StatementWithContext: React.FC<StatementProps> = ({ size, statement, loading }) => {
  const { rows, rowDetails, collapsedRowIds } = useCollapsedRowFiltering(statement)
  const stopPropagation = useStopClickPropagation()

  const breakdownColumns = useStatementBreakdownColumnsCount()
  const extraColumns = useReportExtraColumnsCount()
  const totalColumns = breakdownColumns + extraColumns + 1

  const { rowsRef, onScroll } = useRowScrollShadows()
  const { templateRef, trendlineWidth, maxTitleCellWidth, maxTotalCellWidth } = useRowCellSizes(
    rowsRef,
    rows,
    size
  )

  return (
    <Container>
      <StatementRows
        ref={rowsRef}
        className={STATEMENT_ROWS_CLASSNAME}
        $trendlineWidth={trendlineWidth}
        $maxTitleCellWidth={maxTitleCellWidth}
        $maxTotalCellWidth={maxTotalCellWidth}
        $totalColumns={totalColumns}
        onScroll={onScroll}
        onClick={stopPropagation}
      >
        <StatementHeader size={size} />
        {loading && <LoadingRows size={size} />}
        {React.useMemo(
          () =>
            rows.map((row, i) => (
              <StatementRowComponent
                key={`${row.rowId || i}_${collapsedRowIds.has(row.rowId)}`}
                row={row}
                collapsed={collapsedRowIds.has(row.rowId)}
                size={size}
                details={rowDetails.get(normalizeRowId(row))}
              />
            )),
          [collapsedRowIds, rowDetails, rows, size]
        )}
      </StatementRows>
      <div ref={templateRef} css="position: fixed; z-index: -999; opacity: 0; height: 0" />
    </Container>
  )
}

function useRowScrollShadows() {
  const [measureRef, rect] = useMeasure<HTMLDivElement>()
  const rowsRef = useForwardedRef(measureRef)
  const options = useReportDocumentOptions()

  const toggleShadows = React.useCallback((currentTarget?: HTMLDivElement) => {
    if (!currentTarget) return

    const { scrollLeft, offsetWidth, scrollWidth } = currentTarget

    if (scrollLeft === 0) {
      currentTarget.classList.remove("scroll-start")
    } else {
      currentTarget.classList.add("scroll-start")
    }

    currentTarget.classList.remove("scroll-end")
    if (offsetWidth + scrollLeft < scrollWidth && scrollWidth > offsetWidth + 10) {
      currentTarget.classList.add("scroll-end")
    }
  }, [])

  React.useEffect(() => {
    const currentTarget = rowsRef.current
    toggleShadows(currentTarget)
  }, [rect, options, rowsRef, toggleShadows])

  const onScroll = React.useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLDivElement>) => {
      toggleShadows(currentTarget)
    },
    [toggleShadows]
  )

  return { rowsRef, onScroll }
}

const LoadingRows: React.FC<{ size?: StatementSize }> = ({ size }) => {
  const isCondensed = size === StatementSize.Condensed
  const breakdownColumns = useStatementBreakdownColumnsCount()
  const extraCount = useReportExtraColumnsCount()
  const extraColumns = isCondensed ? 0 : extraCount

  const columns = isCondensed ? 1 : 1 + breakdownColumns + extraColumns
  const width = isCondensed ? "120px" : "200px"
  const rows = isCondensed ? 5 : 15

  return (
    <StatementLoadingRows>
      {Array.from({ length: rows }).map((_, idx) => (
        <RowStyled key={idx} depth={0}>
          <LoadingCell height={16} width={width} />
          <div />
          {Array.from({ length: columns }).map((__, col) => (
            <LoadingCell key={col} height={16} width={STATEMENT_COLUMN_WIDTH} />
          ))}
        </RowStyled>
      ))}
    </StatementLoadingRows>
  )
}
