import * as React from "react"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import useConstant from "@digits-shared/hooks/useConstant"
import { useIsPrintTheme } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import { animated, PickAnimated, SpringValues, useSpring } from "@react-spring/web"
import { Group } from "@visx/group"
import { ParentSize } from "@visx/responsive"
import { scaleBand, scaleLinear } from "@visx/scale"
import { Bar } from "@visx/shape"
import { extent } from "d3-array"
import { ScaleBand, ScaleLinear } from "d3-scale"
import { v4 as generateUUID } from "uuid"
import { VerticalGradient } from "src/frontend/components/OS/Shared/Charts/Coloring"
import { GlowFilter } from "src/frontend/components/OS/Shared/Charts/GlowFilter"
import {
  SharedBarChartStyles,
  TimeseriesBarChartStyle,
} from "src/frontend/components/OS/Shared/Charts/styles"
import {
  ChartContainer,
  SVGContainer,
} from "src/frontend/components/Shared/Layout/Components/Charts/shared"
import {
  ChartGrid,
  ChartXAxis,
  ChartYAxis,
  X_AXIS_HEIGHT,
  Y_AXIS_WIDTH,
} from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesChartAxis"
import {
  TimeseriesValue,
  TimeseriesValues,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"

const ANIMATION_INITIAL_DELAY = 50

const CHART_BAR_MIN_HEIGHT = 0.5

// <ElementType> is a workaround for TS compiler error triggered by react-springs v9 & styled-components
// https://github.com/pmndrs/react-spring/issues/1515
// By setting these generic react element type we lose the Bar's property inference.
// Once issue is fixed or there is a better work around we should remove the generic type.
const AnimatedBar = animated<React.ElementType>(Bar)

/*
  INTERFACES
*/

interface ChartProps {
  timeseries: TimeseriesValues
  preselectedValue?: TimeseriesValue
  onMouseOver?: (value: TimeseriesValue, index: number) => void
  onMouseOut?: (value?: TimeseriesValue) => void
  onClick?: (value: TimeseriesValue, index: number) => void
  className?: string
  hideGrid?: boolean
  hideAxis?: boolean
  skipAnimations?: boolean
  width: number
  height: number
  chartStyle?: TimeseriesBarChartStyle
}

interface SharedProps {
  chartId: string
  xScale: ScaleBand<number>
  xTicks: number[]
  yScale: ScaleLinear<number, number>
  yTicks: number[]
  timeseries: TimeseriesValues
  width: number
  height: number
  parentWidth: number
  chartStyle: TimeseriesBarChartStyle
}

type BarProps = Pick<SharedProps, "chartId" | "xScale" | "yScale"> & {
  value: TimeseriesValue
  selectedValue: TimeseriesValue | undefined
  onMouseEnter: (value: TimeseriesValue, index: number) => void
  onClick?: (value: TimeseriesValue, index: number) => void
  spring: SpringValues<PickAnimated<{ scale: number }>>
  index: number
  seriesLength: number
}

/*
  COMPONENTS
*/

const xValue = ({ period: { startedAt } }: TimeseriesValue) => startedAt

const yValue = ({
  moneyFlow: {
    value: { amount, currencyMultiplier },
    isNormal,
  },
}: TimeseriesValue) => (Math.abs(amount) * (isNormal ? 1 : -1)) / currencyMultiplier

export const ParentSizedTimeseriesBarChart: React.FC<Omit<ChartProps, "width" | "height">> = ({
  timeseries,
  className,
  preselectedValue,
  onMouseOver,
  onMouseOut,
  onClick,
  hideGrid,
  hideAxis,
  skipAnimations,
  chartStyle,
}) => (
  <ParentSize>
    {(parent) => {
      const { width, height } = parent

      return (
        <TimeseriesBarChart
          timeseries={timeseries}
          className={className}
          preselectedValue={preselectedValue}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onClick={onClick}
          hideGrid={hideGrid}
          hideAxis={hideAxis}
          skipAnimations={skipAnimations}
          chartStyle={chartStyle || SharedBarChartStyles}
          width={width}
          height={height}
        />
      )
    }}
  </ParentSize>
)

export const TimeseriesBarChart: React.FC<ChartProps> = ({
  timeseries,
  className,
  preselectedValue,
  onMouseOver,
  onMouseOut,
  onClick,
  hideGrid,
  hideAxis,
  skipAnimations,
  width,
  height,
  chartStyle: propChartStyle,
}) => {
  const chartStyle = propChartStyle || SharedBarChartStyles

  const chartId = useConstant(generateUUID)
  const isPrintMode = useIsPrintTheme()
  const [selectedValue, setSelectedValue] = React.useState<TimeseriesValue | undefined>(
    preselectedValue
  )

  React.useEffect(() => {
    setSelectedValue(preselectedValue)
    const indexOf = preselectedValue ? timeseries.indexOf(preselectedValue) : -1
    if (preselectedValue && indexOf !== -1) {
      onMouseOver?.(preselectedValue, indexOf)
    }
  }, [onMouseOver, preselectedValue, timeseries])

  const onMouseEnter = React.useCallback(
    (value: TimeseriesValue, index: number) => {
      setSelectedValue(value)
      onMouseOver?.(value, index)
    },
    [onMouseOver]
  )

  const onMouseLeave = React.useCallback(() => {
    const defaultValue = preselectedValue ?? undefined
    setSelectedValue(defaultValue)
    onMouseOut?.(defaultValue)
  }, [onMouseOut, preselectedValue])

  const [minYValue, maxYValue] = React.useMemo(() => {
    const [minVal, maxVal] = extent(timeseries, yValue)
    return [Math.min(minVal ?? 0, 0), Math.max(maxVal ?? 0, 0)]
  }, [timeseries])

  const immediate = skipAnimations || isPrintMode
  const delay = immediate ? 0 : ANIMATION_INITIAL_DELAY
  const spring = useSpring({
    config: {
      tension: 250,
      friction: 70,
      mass: 3,
    },
    from: { scale: 0.1 },
    to: { scale: 1 },
    delay,
    immediate,
  })

  if (!timeseries.length) return null

  if (width === 0 || height === 0) return null

  const svgHeight = hideAxis ? height : height - X_AXIS_HEIGHT
  const yScale: ScaleLinear<number, number> = scaleLinear({
    range: [svgHeight, 0],
    round: true,
    domain: [minYValue, maxYValue],
  })

  const xMin = hideAxis ? 0 : Y_AXIS_WIDTH / 3
  const xMaxPadding = hideAxis ? Y_AXIS_WIDTH : Y_AXIS_WIDTH * 1.3
  const xMax = hideAxis ? width : width - xMaxPadding

  const xScale = scaleBand({
    range: [xMin, xMax],
    round: true,
    domain: timeseries.map(xValue),
    paddingInner: 0.5,
  })

  const barsLeft = hideAxis ? 0 : Y_AXIS_WIDTH

  return (
    <ChartContainer className={className} width={width} height={height}>
      <SVGContainer width={width} height={svgHeight} onMouseLeave={onMouseLeave}>
        <GlowFilter id={`${chartId}-glow`} standardDeviation={hideAxis ? 1 : 2.5} />
        <VerticalGradient
          id={`${chartId}-bar-fill`}
          color={chartStyle.barFillColor}
          bottomColor={chartStyle.barFillBottomColor}
        />
        <VerticalGradient
          id={`${chartId}-bar-negative-fill`}
          color={chartStyle.barFillNegativeColor}
          bottomColor={chartStyle.barFillNegativeBottomColor}
        />
        <VerticalGradient
          id={`${chartId}-bar-fill-inactive`}
          color={chartStyle.barFillInactiveColor}
          bottomColor={chartStyle.barFillInactiveBottomColor}
        />
        <VerticalGradient
          id={`${chartId}-bar-fill-selected`}
          color={chartStyle.barFillSelectedColor}
          bottomColor={chartStyle.barFillSelectedBottomColor}
        />

        {!hideGrid && (
          <ChartGrid
            yScale={yScale}
            width={width}
            height={svgHeight}
            axisStyle={chartStyle}
            yAxisWidth={Y_AXIS_WIDTH}
          />
        )}
        <Group top={0} left={barsLeft}>
          {timeseries.map((value, i) => (
            <ValueBar
              chartId={chartId}
              key={`bar-${i}`}
              xScale={xScale}
              yScale={yScale}
              value={value}
              selectedValue={selectedValue}
              onMouseEnter={onMouseEnter}
              onClick={onClick}
              spring={spring}
              index={i}
              seriesLength={timeseries.length}
            />
          ))}
        </Group>
        {!hideAxis && (
          <>
            <ChartXAxis
              timeseries={timeseries}
              xScale={xScale}
              height={svgHeight}
              width={width}
              axisStyle={chartStyle}
              yAxisWidth={Y_AXIS_WIDTH}
            />
            <ChartYAxis
              timeseries={timeseries}
              yScale={yScale}
              height={svgHeight}
              axisStyle={chartStyle}
              yAxisWidth={Y_AXIS_WIDTH}
              width={width}
            />
          </>
        )}
      </SVGContainer>
    </ChartContainer>
  )
}

const ValueBar: React.FC<BarProps> = ({
  chartId,
  xScale,
  yScale,
  value,
  onMouseEnter,
  onClick,
  selectedValue,
  spring,
  index,
  seriesLength,
}) => {
  const yVal = yValue(value)
  const zeroY = yScale(0) ?? 0
  const negative = yVal < 0

  const barWidth = xScale.bandwidth()
  const barHeight = Math.max(zeroY - (yScale(Math.abs(yVal)) ?? 0), yVal ? CHART_BAR_MIN_HEIGHT : 0)
  const barX = xScale(xValue(value))
  const barY = negative ? zeroY : spring.scale.to((s: number) => zeroY - s * barHeight)

  const mouseEnter = React.useCallback(
    () => onMouseEnter(value, index),
    [onMouseEnter, value, index]
  )
  const click = React.useCallback(() => onClick?.(value, index), [onClick, value, index])

  const cornerRadius = barWidth > 4 ? 2 : 1

  const isSelected =
    selectedValue === undefined
      ? index === seriesLength - 1
      : dateTimeHelper.arePeriodsEqual(selectedValue.period, value.period)

  const isInactive = !isSelected
  const fill = isInactive
    ? `url(#${chartId}-bar-fill-inactive)`
    : negative
      ? `url(#${chartId}-bar-negative-fill)`
      : `url(#${chartId}-bar-fill)`

  return (
    <>
      <AnimatedBar
        width={barWidth}
        height={spring.scale.to((s: number) => s * barHeight)}
        x={barX}
        y={barY}
        rx={cornerRadius}
        ry={cornerRadius}
        fill={fill}
        strokeWidth={0}
      />
      {/* Add a rect that covers the bar and its axis label to capture mouse events */}
      <rect
        height="100%"
        width={barWidth}
        x={barX}
        y={0}
        fill={colors.transparent}
        onMouseEnter={mouseEnter}
        onClick={click}
        css={onClick ? { cursor: "pointer" } : undefined}
      />
    </>
  )
}
