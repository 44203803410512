import * as React from "react"
import * as ReactDOM from "react-dom"
import { SvgBuilding07 } from "@digits-shared/components/SVGIcons/line/Building07.svg"
import { SvgCoinsHand } from "@digits-shared/components/SVGIcons/line/CoinsHand.svg"
import { SvgFile07 } from "@digits-shared/components/SVGIcons/line/File07.svg"
import { SvgWallet01 } from "@digits-shared/components/SVGIcons/line/Wallet01.svg"
import styled from "styled-components"
import { SharedEntities } from "src/frontend/components/OS/Springboard/Applications/SharedWithMe/Shared"
import { SIDEBAR_PATH_SVG_STYLES } from "src/frontend/components/Shared/NavSidebar/sidebarConstants"
import { SidebarRow } from "src/frontend/components/Shared/NavSidebar/SidebarExpando"
import routes from "src/frontend/routes"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"

/*
  STYLES
*/

const SharedRow = styled(SidebarRow)`
  svg {
    ${SIDEBAR_PATH_SVG_STYLES};
  }
`

const PartyRow = styled(SharedRow)`
  svg {
    fill: none !important;
    * {
      stroke: none !important;
    }
  }
`
/*
  COMPONENTS
*/

export const ObjectKindSelector: React.FC<{
  entities: SharedEntities
}> = ({ entities }) => {
  const modalRootEl = document.querySelector<HTMLElement>("#nav-sidebar-contents")
  if (!modalRootEl) return null

  return ReactDOM.createPortal(
    <>
      {!!entities.reportPackages.length && (
        <SharedRow Icon={SvgFile07} route={routes.sharedWithMeReportPackages}>
          Reports
        </SharedRow>
      )}
      {!!entities.categories.length && (
        <SharedRow Icon={SvgWallet01} route={routes.sharedWithMeCategories}>
          Categories
        </SharedRow>
      )}
      {!!entities.departments.length && (
        <SharedRow Icon={SvgBuilding07} route={routes.sharedWithMeDepartments}>
          Departments
        </SharedRow>
      )}
      {!!entities.transactions.length && (
        <SharedRow Icon={SvgCoinsHand} route={routes.sharedWithMeTransactions}>
          Transactions
        </SharedRow>
      )}
      {Array.from(entities.parties.keys()).map((role) => {
        const partyRole = FrontendPartyRole.findByRole(role)

        return (
          <PartyRow
            key={role}
            Icon={partyRole.SearchSvgIcon}
            route={routes.sharedWithMeParties}
            params={{ partyRole: partyRole.urlKey }}
            isExact
          >
            {partyRole.displayName}
          </PartyRow>
        )
      })}
    </>,
    modalRootEl
  )
}
