import * as React from "react"
import { ReactNode } from "react"
import {
  EntityCategory,
  EntityDepartment,
  EntityParty,
  ObjectEntities,
  ObjectKind,
  PartyRole,
  TextComponentConfigTag,
  TextTagOptions,
} from "@digits-graphql/frontend/graphql-bearer"
import { defined, uniqueBy } from "@digits-shared/helpers/filters"
import stringHelper from "@digits-shared/helpers/stringHelper"
import urlHelper, { URL_REGEX } from "@digits-shared/helpers/urlHelper"
import htmlEntities from "he"
import { SUPPORTED_PARTY_ROLES } from "src/frontend/types/FrontendPartyRole"
import {
  DATA_DECORATOR,
  DATA_ENTITY_KIND,
  DATA_ID,
  decoratedEntityToEntityKind,
  Decorator,
  DECORATOR_CLASS,
  EntityTagToken,
} from "src/shared/components/EditableContent/editableContentConstants"
import DOMSanitize from "src/shared/components/ObjectEntities/DOMSanitize"
import {
  CategoryTag,
  DepartmentTag,
  PartyTag,
  UserTag,
} from "src/shared/components/ObjectEntities/Entities"
import { EntityPopOverComponent } from "src/shared/components/ObjectEntities/entityPopOverTypes"

const ENTITY_REGEX = /([^|>]+)\|([^|>]+)(?:\|([^>]+))?/
const ENTITIES_TAGS_REGEX = /(<[^|>]+\|[^>]+>)/i
const ENTITY_TAG_REGEX = new RegExp(`<${ENTITY_REGEX.source}>`) // includes `<` `>`

export function extractEntitiesAsTags(
  text: string,
  legalEntityId: string
): TextComponentConfigTag[] {
  return text
    .split(ENTITIES_TAGS_REGEX)
    .flatMap<TextComponentConfigTag | undefined>((part) => {
      const entity = part.match(ENTITY_TAG_REGEX)
      if (!entity) return undefined

      const [_, kindStr, id, title] = entity
      const { kind, options } = getObjectKindFromString(kindStr || "")
      if (!kind) return undefined

      return {
        displayText: title || "",
        objectId: {
          legalEntityId,
          id: id || "",
          kind,
        },
        options,
      }
    })
    .filter(defined)
    .filter(uniqueBy((tag) => `${tag.objectId.id}${tag.options?.partyRole || ""}`))
}

export function convertToDigitsEntityTags(text: string) {
  // reformat entities from `<EntityKind|ID|Title>` to well-formed `<digits-entity data-id="ID" data-decorator="EntityKind">Title</digits-entity>`
  // so that `DOMPurify` correctly parses out the custom elements
  return text
    .split(ENTITIES_TAGS_REGEX)
    .flatMap((part) => {
      const entity = part.match(ENTITY_TAG_REGEX)
      if (!entity) return part

      const [_, entityName, entityId, title] = entity
      return `<digits-entity ${DATA_DECORATOR}="${entityName}" title="${title}" ${DATA_ID}="${entityId}">${title}</digits-entity>`
    })
    .join("")
    .replaceAll("\n", "<BR />") // support legacy comments with \n line breaks
}

function getObjectKindFromString(kindStr: string): { kind?: ObjectKind; options?: TextTagOptions } {
  const dynamicEntityRole = `Entity${kindStr}Role` as PartyRole
  const dynamicRole = `${kindStr}Role` as PartyRole
  const partyRole = PartyRole[dynamicEntityRole] || PartyRole[dynamicRole]
  if (SUPPORTED_PARTY_ROLES.includes(partyRole)) {
    return { kind: ObjectKind.Party, options: { partyRole } }
  }

  // TODO: remove fallback when insights include roles
  const dynamicKind = kindStr as ObjectKind
  if (ObjectKind[dynamicKind] === ObjectKind.Party) {
    return { kind: ObjectKind.Party, options: { partyRole: PartyRole.EntityVendorRole } }
  }

  return { kind: ObjectKind[dynamicKind] }
}

/*
 INTERFACE
*/

export type HoverableEntity = EntityParty | EntityCategory | EntityDepartment

interface EntityTagsProps {
  text: string
  decode?: boolean
  editMode?: boolean
  entities?: ObjectEntities | null
  entityPopOver?: EntityPopOverComponent
  disableHovers?: boolean
  formatChildNode?: (el: HTMLElement) => ReactNode | undefined
}

interface EntityTagProps {
  index: number
  entityId: string
  options: TextTagOptions | undefined
  title?: string
  editMode?: boolean
  entities: ObjectEntities
  entityPopOver?: EntityPopOverComponent
  disableHover?: boolean
  formatChildNode?: (el: HTMLElement) => ReactNode | undefined
}

interface EditableTagProps {
  decorator: Decorator
  options: TextTagOptions | undefined
  entityId: string
  children?: React.ReactNode
}

interface HtmlParserProps {
  index: number
  node: ChildNode
  editMode?: boolean
  entities?: ObjectEntities | null
  entityPopOver?: EntityPopOverComponent
  disableHovers?: boolean
  formatChildNode?: (el: HTMLElement) => ReactNode | undefined
}

interface HtmlEntityProps {
  index: number
  entityName: string
  entityId: string
  title: string
  editMode?: boolean
  entities?: ObjectEntities | null
  entityPopOver?: EntityPopOverComponent
  disableHover?: boolean
}

/*
 COMPONENTS
*/

const EntitiesParser: React.FC<EntityTagsProps> = ({
  text,
  entities,
  decode,
  editMode,
  entityPopOver,
  disableHovers,
  formatChildNode,
}) =>
  React.useMemo(() => {
    const decodedText = decode ? htmlEntities.decode(text) : text
    return (
      <EntityTags
        text={decodedText}
        entities={entities}
        editMode={editMode}
        entityPopOver={entityPopOver}
        disableHovers={disableHovers}
        formatChildNode={formatChildNode}
      />
    )
  }, [decode, text, entities, editMode, entityPopOver, disableHovers, formatChildNode])

export default EntitiesParser

const EditableTag: React.FC<EditableTagProps> = ({ decorator, entityId, options, children }) => {
  const entityKind = decoratedEntityToEntityKind(options?.partyRole || decorator)
  const data = {
    [DATA_ID]: entityId,
    [DATA_DECORATOR]: decorator,
    [DATA_ENTITY_KIND]: entityKind,
  }

  return (
    <span className={[DECORATOR_CLASS, decorator].join(" ")} {...data}>
      {children}
    </span>
  )
}

const EntityTags: React.FC<EntityTagsProps> = ({
  text,
  entities,
  editMode,
  entityPopOver,
  disableHovers,
  formatChildNode: formatChildNode,
}) => {
  // reformat entities from `<EntityKind|ID|Title>` to well-formed `<digits-entity data-id="ID" data-decorator="EntityKind">Title</digits-entity>`
  // so that `DOMPurify` correctly parses out the custom elements
  const entitiesText = convertToDigitsEntityTags(text)

  const sanitizedFragment = DOMSanitize.sanitize(entitiesText)

  const tags = Array.from(sanitizedFragment.childNodes).map(
    (node, idx) => (
      <HtmlParser
        key={idx}
        index={idx}
        node={node}
        editMode={editMode}
        entities={entities}
        entityPopOver={entityPopOver}
        disableHovers={disableHovers}
        formatChildNode={formatChildNode}
      />
    ),
    []
  )

  return editMode ? <>{tags}&nbsp;</> : <>{tags}</>
}

const HtmlParser: React.FC<HtmlParserProps> = ({
  index,
  node,
  editMode,
  entities,
  entityPopOver,
  disableHovers,
  formatChildNode,
}) => {
  const element = node as HTMLElement
  if (!element.tagName) {
    return <UrlTags text={element.nodeValue || ""} />
  }

  if (element.tagName === "DIGITS-ENTITY") {
    const entityId = element.getAttribute(DATA_ID) || ""
    const entityName = element.getAttribute(DATA_DECORATOR) || ""
    const title = element.innerText || element.getAttribute("title") || ""

    return (
      <HtmlEntity
        index={index}
        entityId={entityId}
        entityName={entityName}
        title={title}
        editMode={editMode}
        entities={entities}
        entityPopOver={entityPopOver}
        disableHover={disableHovers}
      />
    )
  }

  let children: ReactNode[] = []
  if (element.childNodes?.length) {
    children = Array.from(element.childNodes).map(
      (elChild, idx) => (
        <HtmlParser
          key={idx}
          index={idx}
          node={elChild}
          editMode={editMode}
          entities={entities}
          entityPopOver={entityPopOver}
          disableHovers={disableHovers}
        />
      ),
      []
    )
  }

  // Support re-writing node contents based on data attributes.
  if (element.childNodes?.length === 1) {
    const newChild = formatChildNode?.(element)
    children = newChild ? [newChild] : children
  }

  const style: Record<string, unknown> = {}
  if (element?.style) {
    for (let i = 0; i < element.style.length; i += 1) {
      const styleName = element.style[i] as keyof CSSStyleDeclaration
      const styleCameName = stringHelper.camelCase(styleName.toString())
      style[styleCameName] = element.style[styleName]
    }
  }
  const props: Record<string, unknown> = {
    style,
  }

  Object.entries({ href: "href", rel: "rel", class: "className" }).forEach(
    ([attrName, reactName]) => {
      const attr = element?.getAttribute(attrName)
      if (attr) {
        props[reactName] = attr
        props.target = "_blank"
      }
    }
  )

  return React.createElement(element.tagName.toLowerCase(), props, ...children)
}

const HtmlEntity: React.FC<HtmlEntityProps> = ({
  index,
  entityName,
  entityId,
  title,
  editMode,
  entities,
  entityPopOver,
  disableHover,
}) => {
  const key = `${entityName}_${index}_${entityId}${editMode ? "_edit" : ""}`
  if (!entities) {
    return <>{title || entityName}</>
  }

  const { kind, options } = getObjectKindFromString(entityName)

  switch (kind) {
    case ObjectKind.User:
      return (
        <UserEntity
          key={key}
          index={index}
          entityId={entityId}
          title={title}
          entities={entities}
          editMode={editMode}
          options={options}
        />
      )

    case ObjectKind.Category:
      return (
        <CategoryEntity
          key={key}
          index={index}
          entityId={entityId}
          title={title}
          entities={entities}
          editMode={editMode}
          options={options}
          entityPopOver={entityPopOver}
          disableHover={disableHover}
        />
      )

    case ObjectKind.Party:
      return (
        <PartyEntity
          key={key}
          index={index}
          entityId={entityId}
          title={title}
          entities={entities}
          editMode={editMode}
          options={options}
          entityPopOver={entityPopOver}
          disableHover={disableHover}
        />
      )

    case ObjectKind.Department:
      return (
        <DepartmentEntity
          key={key}
          index={index}
          entityId={entityId}
          title={title}
          entities={entities}
          editMode={editMode}
          options={options}
          entityPopOver={entityPopOver}
          disableHover={disableHover}
        />
      )

    default:
      console.warn("Unsupported entity", entityName, entityId, title)
      return <>{title || entityName}</>
  }
}

const UrlTags: React.FC<EntityTagsProps> = ({ text }) => (
  <>
    {text.split(URL_REGEX).flatMap((str, idx) => {
      if (!str || str.search(URL_REGEX) === -1) return str

      if (!urlHelper.isValidHttpUrl(str)) return str

      return (
        <a key={`${str}_${idx}`} href={str} target="_blank" rel="noreferrer">
          {str}
        </a>
      )
    })}
  </>
)

const UserEntity: React.FC<EntityTagProps> = ({
  index,
  entityId,
  options,
  title,
  entities,
  editMode,
}) => {
  const user = entities.users?.find((u) => u.id === entityId)
  if (!user) {
    if (title) return <span>{title}</span>

    console.warn("User entity not found for id:", entityId)
    return <span key={`missing_user_${index}_${entityId}`}>User</span>
  }

  if (editMode) {
    return (
      <EditableTag decorator={Decorator.User} options={options} entityId={entityId}>
        {EntityTagToken.Mention}
        {user.givenName || user.givenName || user.emailAddress}
      </EditableTag>
    )
  }
  return <UserTag user={user} />
}

const CategoryEntity: React.FC<EntityTagProps> = ({
  index,
  entityId,
  options,
  title,
  entities,
  editMode,
  entityPopOver,
  disableHover,
}) => {
  const category = entities.categories?.find((c) => c.id === entityId)
  if (!category) {
    if (title) return <span>{title}</span>

    console.warn("Category entity not found for id:", entityId)
    return <span key={`missing_category_${index}_${entityId}`}>Category</span>
  }

  if (editMode) {
    return (
      <EditableTag decorator={Decorator.Category} options={options} entityId={entityId}>
        {EntityTagToken.Mention}
        {category.name}
      </EditableTag>
    )
  }
  return <CategoryTag entity={category} entityPopOver={entityPopOver} disableHover={disableHover} />
}

const PartyEntity: React.FC<EntityTagProps> = ({
  index,
  entityId,
  options,
  title,
  entities,
  editMode,
  entityPopOver,
  disableHover,
}) => {
  const party = entities.parties?.find((p) => p.id === entityId)

  if (!party) {
    if (title) return <span>{title}</span>

    console.warn("Party entity not found for id:", entityId)
    return <span key={`missing_party_${index}_${entityId}`}>Party</span>
  }

  if (editMode) {
    return (
      <EditableTag decorator={Decorator.Party} options={options} entityId={entityId}>
        {EntityTagToken.Mention}
        {party.name}
      </EditableTag>
    )
  }
  return <PartyTag entity={party} entityPopOver={entityPopOver} disableHover={disableHover} />
}

const DepartmentEntity: React.FC<EntityTagProps> = ({
  index,
  entityId,
  options,
  title,
  entities,
  editMode,
  entityPopOver,
  disableHover,
}) => {
  const department = entities.departments?.find((d) => d.id === entityId)

  if (!department) {
    if (title) return <span>{title}</span>

    console.warn("Department entity not found for id:", entityId)
    return <span key={`missing_department_${index}_${entityId}`}>Department</span>
  }

  if (editMode) {
    return (
      <EditableTag decorator={Decorator.Department} options={options} entityId={entityId}>
        {EntityTagToken.Mention}
        {department.name}
      </EditableTag>
    )
  }
  return (
    <DepartmentTag entity={department} entityPopOver={entityPopOver} disableHover={disableHover} />
  )
}
