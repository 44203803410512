import * as React from "react"
import { DepartmentChartConfig } from "@digits-graphql/frontend/graphql-bearer"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import { hasExactlyOne } from "@digits-shared/helpers/arrayHelper"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import urlHelper from "@digits-shared/helpers/urlHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import { validate } from "uuid"
import { StackableBarData } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Shared"
import { StackableBarChartLegendType } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/StackableBarChartContext"
import { DimensionWidgetEmptyView } from "src/frontend/components/OS/Shared/Widgets/DimensionWidget"
import { BarChartComponent } from "src/frontend/components/Shared/Layout/Components/Charts/BarChartComponent"
import { StackedBarChartComponent } from "src/frontend/components/Shared/Layout/Components/Charts/StackedBarChartComponent"
import {
  TimeseriesValue,
  toTimeseries,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { useCombinedDepartmentsSeries } from "src/frontend/components/Shared/Layout/Components/Departments/useCombinedDepartmentsSeries"
import { useDepartmentSummaryByTimeComponentData } from "src/frontend/components/Shared/Layout/Components/Departments/useDepartmentSummaryByTimeComponentData"
import { useTopDepartmentsSeries } from "src/frontend/components/Shared/Layout/Components/Departments/useTopDepartmentsSeries"
import {
  DepartmentComponentIcon,
  DepartmentComponentTitle,
} from "src/frontend/components/Shared/Layout/Components/Headers/ComponentEntityIcons"
import { ComponentSummary } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useDepartmentLinkPath } from "src/frontend/components/Shared/Layout/hooks/useEntityDetailsViewPaths"
import { MatchedComponent, SizingProps } from "src/frontend/components/Shared/Layout/types"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import useIntervalOrigin from "src/shared/hooks/useIntervalOrigin"

/*
  INTERFACES
*/

interface DepartmentChartComponentProps extends SizingProps {
  layoutId: string
  component: MatchedComponent<"departmentChart">
  // used by Portals to override the origin (time selectors)
  configOverride?: DepartmentChartConfig
  componentSize: ComponentSize
  skipAnimations: boolean
}

/*
  COMPONENTS
*/

export const DepartmentChartComponent: React.FC<DepartmentChartComponentProps> = ({
  layoutId,
  component,
  componentSize,
  height,
  skipAnimations,
  configOverride,
}) => {
  const {
    dataId,
    config: { type, departmentChart },
  } = component
  const chartConfig = configOverride ?? departmentChart
  const { origin } = chartConfig

  const isSmall = componentSize === ComponentSize.Small || componentSize === ComponentSize.PageSmall

  const { department, dimensionalSummary, loading } = useDepartmentSummaryByTimeComponentData(
    chartConfig,
    type,
    layoutId,
    dataId
  )
  const multiTimeseries = React.useMemo(
    () => dimensionalSummary?.map((ds) => toTimeseries(ds.department.name, ds.summaries)) ?? [],
    [dimensionalSummary]
  )
  const combinedSeries = useCombinedDepartmentsSeries(multiTimeseries)
  const data = useTopDepartmentsSeries(dimensionalSummary)

  const icon = (
    <DepartmentComponentIcon
      department={department}
      intervalOrigin={origin}
      componentSize={componentSize}
    />
  )
  const name = (
    <DepartmentComponentTitle
      department={department}
      intervalOrigin={origin}
      componentSize={componentSize}
    >
      {component.title}
    </DepartmentComponentTitle>
  )

  const { history } = useRouter()
  const departmentPath = useDepartmentLinkPath(department, origin)
  const generatePath = useFrontendPathGenerator()

  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(undefined)
  const onMouseOver = React.useCallback(
    (value: TimeseriesValue | StackableBarData | undefined, index: number) => {
      setSelectedIndex(index)
    },
    []
  )

  const onMouseOut = React.useCallback((value?: TimeseriesValue | StackableBarData) => {
    setSelectedIndex(undefined)
  }, [])

  const onChartClick = React.useCallback(
    (_: TimeseriesValue, index: number) => {
      if (!departmentPath) return
      const path = urlHelper.addSearchParams(departmentPath, { highlight: index })
      if (!path) return

      setSelectedIndex(index)
      history.push(path)
    },
    [departmentPath, history]
  )

  const onStackBarClick = React.useCallback(
    (barData: StackableBarData, periodIndex: number) => {
      if (!validate(barData.dataId)) return
      history.push(
        generatePath(routes.departmentDetails, {
          departmentId: barData.dataId,
          ...origin,
          highlight: periodIndex,
        })
      )
    },
    [history, generatePath, origin]
  )

  if (multiTimeseries.length > 0 && (isSmall || hasExactlyOne(multiTimeseries))) {
    return (
      <BarChartComponent
        componentSize={componentSize}
        title={name}
        icon={icon}
        timeseries={combinedSeries ?? []}
        height={height}
        skipAnimations={skipAnimations}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={onChartClick}
        selectedIndex={selectedIndex}
      />
    )
  }

  return (
    <>
      <ComponentSummary
        componentSize={componentSize}
        title={name}
        timeseries={combinedSeries}
        icon={icon}
        selectedIndex={selectedIndex}
      />
      <InvertValuesContext.Provider value={true}>
        <StackedBarChartComponent
          componentSize={componentSize}
          height={height}
          data={data}
          loading={loading}
          legend={StackableBarChartLegendType.Mini}
          onBarClick={onStackBarClick}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          emptyNode={<EmptyChartView />}
        />
      </InvertValuesContext.Provider>
    </>
  )
}

const EmptyChartView: React.FC = () => {
  const intervalOrigin = useIntervalOrigin()

  return (
    <DimensionWidgetEmptyView>
      No Departments for past {dateTimeHelper.displayNameForInterval(intervalOrigin.interval, 12)}
    </DimensionWidgetEmptyView>
  )
}
