import * as React from "react"
import { InvertValuesContext } from "@digits-shared/components/Contexts/InvertValuesContext"
import { hasExactlyOne } from "@digits-shared/helpers/arrayHelper"
import { StackableBarChartLegendType } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/StackableBarChartContext"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/ViewVersionContext"
import { BarChartConfig } from "src/frontend/components/Shared/Layout/Components/Charts/Configs/BarChartConfig"
import { StackedBarChartComponent } from "src/frontend/components/Shared/Layout/Components/Charts/StackedBarChartComponent"
import {
  toSortedTimeseriesValues,
  toTimeseries,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { DepartmentSummaries } from "src/frontend/components/Shared/Layout/Components/Departments/filterDepartmentAncestors"
import { useCombinedDepartmentsSeries } from "src/frontend/components/Shared/Layout/Components/Departments/useCombinedDepartmentsSeries"
import { useDepartmentSummaryByTimeLiveData } from "src/frontend/components/Shared/Layout/Components/Departments/useDepartmentSummaryByTimeLiveData"
import { useTopDepartmentsSeries } from "src/frontend/components/Shared/Layout/Components/Departments/useTopDepartmentsSeries"
import { DepartmentIconStyled } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentEntityIcons"
import { ComponentSummary } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentSummary"
import { useComponentConfigTitle } from "src/frontend/components/Shared/Layout/Components/useComponentTitle"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { MatchedConfig, SizingProps } from "src/frontend/components/Shared/Layout/types"

/*
  INTERFACES
*/

interface DepartmentChartConfigProps extends SizingProps {
  config: MatchedConfig<"departmentChart">
  skipAnimations: boolean
  layoutId: string
  onSummaries?: (summaries: DepartmentSummaries[]) => void
}

/*
  COMPONENTS
*/
export const DepartmentChartConfig: React.FC<DepartmentChartConfigProps> = ({
  config,
  height,
  skipAnimations,
  layoutId,
  onSummaries,
}) => {
  const viewId = useViewVersion()

  const { department, dimensionalSummary, loading } = useDepartmentSummaryByTimeLiveData(
    config.departmentChart,
    config.type
  )
  const data = useTopDepartmentsSeries(dimensionalSummary)

  const { title } = useComponentConfigTitle(config, { layoutId, viewId })

  const timeseries = React.useMemo(
    () => toSortedTimeseriesValues(dimensionalSummary?.[0]?.summaries),
    [dimensionalSummary]
  )

  const multiTimeseries = React.useMemo(
    () => dimensionalSummary?.map((ds) => toTimeseries(ds.department.name, ds.summaries)) ?? [],
    [dimensionalSummary]
  )
  const combinedSeries = useCombinedDepartmentsSeries(multiTimeseries)

  // remove this component if the results are empty
  React.useEffect(() => {
    if (!loading) {
      onSummaries?.(dimensionalSummary ?? [])
    }
  }, [dimensionalSummary, loading, onSummaries])

  const icon = React.useMemo(() => {
    if (!department) return null

    return (
      <DepartmentIconStyled
        componentSize={ComponentSize.PageSmall}
        subjectDisplayKey="Building07"
      />
    )
  }, [department])

  if (hasExactlyOne(multiTimeseries)) {
    return (
      <BarChartConfig
        name={title}
        timeseries={timeseries}
        skipAnimations={skipAnimations}
        height={height}
        icon={icon}
      />
    )
  }

  return (
    <>
      <ComponentSummary
        componentSize={ComponentSize.Small}
        title={title}
        timeseries={combinedSeries}
        icon={icon}
      />
      <InvertValuesContext.Provider value={true}>
        <StackedBarChartComponent
          componentSize={ComponentSize.Small}
          height={height}
          data={data}
          loading={loading}
          legend={StackableBarChartLegendType.None}
        />
      </InvertValuesContext.Provider>
    </>
  )
}
