export const userPreferenceKeys = {
  animationConfigDevTools: "acdt",
  reportFileSourceDevTools: "rfsdt",
  gridDevTools: "gdt",
  hideInviteAccountantToast: "hide-invite-accountant-toast",
  viewedAssistant: "viewed-assistant",
  newViewVersionAlert: "nvva",
  reportDocViewer: "rdv",
  mockInvoicingData: "minv",
  mockInvoicingDataStore: "mids",
  vendorDirectory: "vendor-directory",
}
