import * as React from "react"
import { Route, Switch } from "react-router-dom"
import { DigitsRoute } from "@digits-shared/components/Router/DigitsRoute"
import { LoggedRedirect } from "@digits-shared/components/Router/LoggedRedirect"
import { ProtectedRoute } from "@digits-shared/components/Router/ProtectedRoute"
import { isMobile } from "@digits-shared/helpers/devicesHelper"
import useConstant from "@digits-shared/hooks/useConstant"
import { useCurrentRoute } from "@digits-shared/hooks/useCurrentRoute"
import useRouter from "@digits-shared/hooks/useRouter"
import { AspectCode } from "@digits-shared/session/SessionTypes"
import { useSearchKeyLauncher } from "src/frontend/components/OS/Springboard/Applications/Search/useSearchKeyLauncher"
import { useObjectSharingState } from "src/frontend/hooks/useObjectSharingState"
import routes from "src/frontend/routes"
import { ObjectSharingStatus } from "src/frontend/session/ObjectSharingStatus"

/*
  OS Details Views
*/

const TransactionDetails = React.lazy(
  () => import("src/frontend/components/OS/Details/Transaction/TransactionDetails")
)

const CategoryDetails = React.lazy(
  () => import("src/frontend/components/OS/Details/Category/CategoryDetails")
)

const DepartmentDetails = React.lazy(
  () => import("src/frontend/components/OS/Details/Departments/DepartmentDetailsView")
)

const PartyDetails = React.lazy(
  () => import("src/frontend/components/OS/Details/Party/PartyDetails")
)

const ReportsDetails = React.lazy(
  () => import("src/frontend/components/OS/Springboard/Applications/Reports/ReportsDetails")
)

const BillingDetails = React.lazy(
  () =>
    import("src/frontend/components/OS/Springboard/Applications/Settings/Billing/BillingDetails")
)

const IdentityVerificationDetails = React.lazy(
  () =>
    import(
      "src/frontend/components/OS/Springboard/Applications/IdentityVerification/IdentityVerificationDetails"
    )
)

const BillDetails = React.lazy(
  () =>
    import("src/frontend/components/OS/Springboard/Applications/BillPay/Details/Bill/BillDetails")
)

const BillPaymentDetails = React.lazy(
  () =>
    import(
      "src/frontend/components/OS/Springboard/Applications/BillPay/Details/BillPayment/BillPaymentDetails"
    )
)

const InvoiceDetails = React.lazy(() =>
  import(
    "src/frontend/components/OS/Springboard/Applications/Invoicing/Details/InvoiceDetails"
  ).then((module) => ({ default: module.InvoiceDetails }))
)

const AccountingDetails = React.lazy(
  () => import("src/frontend/components/OS/Springboard/Applications/Accounting/AccountingDetails")
)

const ConnectionDetails = React.lazy(
  () => import("src/frontend/components/OS/Springboard/Applications/Connections/ConnectionDetails")
)

const SettingsDetails = React.lazy(
  () =>
    import(
      "src/frontend/components/OS/Springboard/Applications/SettingsLegalEntity/SettingsDetails"
    )
)

const LayoutComponentDetailsView = React.lazy(
  () => import("src/frontend/components/Shared/Portals/Details/ComponentDetailsView")
)

// Home Details View

const CustomizeDashboard = React.lazy(
  () => import("src/frontend/components/OS/Home/CustomizeDashboard/CustomizeDashboard")
)

// Sharing

const SharingInvalidLinkDetails = React.lazy(
  () => import("src/shared/components/Sharing/InvalidLinkDetails")
)

const SharingRequestAccessDetails = React.lazy(
  () => import("src/shared/components/Sharing/RequestAccessDetails")
)

// Vault

const FileDetailsView = React.lazy(
  () =>
    import("src/frontend/components/OS/Springboard/Applications/Vault/FileDetails/FileDetailsView")
)

// Bank Reconciliation
const BankReconciliationDetails = React.lazy(
  () =>
    import(
      "src/frontend/components/OS/Springboard/Applications/Reconciliations/Details/ReconciliationDetails"
    )
)

const ClientTransactionReview = React.lazy(
  () =>
    import(
      "src/frontend/components/OS/Springboard/Applications/ClientTransactionReview/DetailsView/ClientTransactionReviewDetailsChrome"
    )
)

const SearchDetailsView = React.lazy(
  () => import("src/frontend/components/OS/Springboard/Applications/Search/SearchDetailsView")
)

export const OSDetails: React.FC = () => {
  const objectSharingPaths = useConstant(() => routes.objectSharingPaths())
  const { objectSharingStatus } = useObjectSharingState()
  const { location } = useRouter()
  useSearchKeyLauncher()

  const { isDeprecatedAccountingRoute, to } = useRequiresDeprecatedAccountingRouteRedirect()

  if (isDeprecatedAccountingRoute && to) {
    return <LoggedRedirect name="replaceLegacyAccountingRoute" to={to} />
  }

  return (
    <React.Suspense fallback={<div />}>
      <Switch location={location}>
        {renderSharingRoutes(objectSharingStatus, objectSharingPaths)}

        {/* OS Details Views */}

        <Route
          path={[routes.search.parameterizedPath]}
          render={() => (
            <ProtectedRoute
              redirectTo={routes.dashboard.generate()}
              component={SearchDetailsView}
            />
          )}
        />

        <Route
          path={routes.legalEntityIdentityVerification.parameterizedPath}
          render={() => (
            <ProtectedRoute
              redirectTo={routes.login.parameterizedPath}
              component={IdentityVerificationDetails}
            />
          )}
        />

        <Route
          path={routes.billDetails.parameterizedPath}
          render={() => (
            <ProtectedRoute redirectTo={routes.login.parameterizedPath} component={BillDetails} />
          )}
        />

        <Route
          path={routes.billPaymentDetails.parameterizedPath}
          render={() => (
            <ProtectedRoute
              redirectTo={routes.login.parameterizedPath}
              component={BillPaymentDetails}
            />
          )}
        />

        <Route
          path={routes.invoiceDetails.parameterizedPath}
          render={() => (
            <ProtectedRoute
              aspectCode={AspectCode.Invoicing}
              redirectTo={routes.login.parameterizedPath}
              component={InvoiceDetails}
            />
          )}
        />

        <Route
          path={routes.connections.parameterizedPath}
          render={() => (
            <ProtectedRoute
              aspectCode={AspectCode.Connections}
              redirectTo={routes.login.parameterizedPath}
              component={ConnectionDetails}
            />
          )}
        />

        <Route
          path={routes.legalEntitySettings.parameterizedPath}
          render={() => (
            <ProtectedRoute
              redirectTo={routes.login.parameterizedPath}
              component={SettingsDetails}
            />
          )}
        />

        <Route
          path={routes.legalEntityHomeEdit.parameterizedPath}
          render={() => (
            <ProtectedRoute
              redirectTo={routes.login.parameterizedPath}
              component={CustomizeDashboard}
            />
          )}
        />

        <Route
          path={routes.layoutComponentDetails.parameterizedPath}
          render={() => (
            <ProtectedRoute
              redirectTo={routes.login.parameterizedPath}
              component={LayoutComponentDetailsView}
            />
          )}
        />

        <Route
          path={routes.vaultFile.parameterizedPath}
          render={() => (
            <ProtectedRoute
              redirectTo={routes.login.parameterizedPath}
              component={FileDetailsView}
              aspectCode={AspectCode.Vault}
            />
          )}
        />

        <Route
          path={routes.reconciliation.parameterizedPath}
          render={() => (
            <ProtectedRoute
              redirectTo={routes.login.parameterizedPath}
              component={BankReconciliationDetails}
              aspectCode={AspectCode.AIBookkeeper}
            />
          )}
        />

        {/* Springboard (LE Level) Views */}
        <Route path={routes.organizationBilling.parameterizedPath} component={BillingDetails} />

        {/* Client Transaction Review */}
        {/* only desktop renders as a Details view */}
        {!isMobile && (
          <Route
            path={routes.clientTransactionReview.parameterizedPath}
            component={ClientTransactionReview}
          />
        )}
      </Switch>
    </React.Suspense>
  )
}

/** Return routes for object statuses that take priority over showing details content */
function renderSharingRoutes(
  objectSharingStatus: ObjectSharingStatus,
  objectSharingPaths: string[]
) {
  switch (objectSharingStatus) {
    case ObjectSharingStatus.AccessRequestRequired:
    case ObjectSharingStatus.AccessRequestPending:
      // A common request access page which delegates to an object-specific preview renderer
      return <Route path={objectSharingPaths} component={SharingRequestAccessDetails} />
    case ObjectSharingStatus.IntentRevoked:
    case ObjectSharingStatus.NoAccess:
      // A common invalid link page.
      return <Route path={objectSharingPaths} component={SharingInvalidLinkDetails} />
    case ObjectSharingStatus.Unknown:
    default:
      // Return must be an array and not React Fragment or other React Element as it will prevent
      // the React Router Switch above from correctly determining the matching route.
      return [
        <Route
          key="transaction-details"
          exact
          path={[
            routes.transactionDetails.parameterizedPath,
            routes.defaultTransactionDetails.parameterizedPath,
          ]}
          component={TransactionDetails}
        />,
        <Route
          key="accounting-details"
          path={[
            routes.accountingDetail.parameterizedPath,
            routes.accountingDetailEdit.parameterizedPath,
          ]}
          component={AccountingDetails}
        />,
        <Route
          key="department-details"
          path={[routes.departmentDetails.parameterizedPath]}
          component={DepartmentDetails}
        />,
        <Route
          key="category-details"
          path={[
            routes.categoryDetails.parameterizedPath,
            routes.categoryDetailsComments.parameterizedPath,
          ]}
          component={CategoryDetails}
        />,
        <Route
          key="party-details"
          path={[
            routes.partyDetailsRedirectForComment.parameterizedPath,
            routes.partyDetailsRedirectForThread.parameterizedPath,
            routes.partyDetails.parameterizedPath,
          ]}
          component={PartyDetails}
        />,
        <Route
          key="report-package"
          path={routes.reportPackage.parameterizedPath}
          component={ReportsDetails}
        />,
      ]
  }
}

function useRequiresDeprecatedAccountingRouteRedirect() {
  const { location } = useRouter()
  const currentRoute = useCurrentRoute(routes)
  const parameters = currentRoute.getParametersFromPath(location.pathname)
  const checkRoute = (route: DigitsRoute) => route.isRouteOrChildOfRoute(currentRoute)
  const generateTo = (route: DigitsRoute) => route.generate(parameters)

  switch (true) {
    case checkRoute(routes.accountingTransactionDetailDeprecated):
      return {
        isDeprecatedAccountingRoute: true,
        to: parameters.transactionId
          ? generateTo(routes.accountingDetailEdit)
          : generateTo(routes.accountingDetail),
      }
    case checkRoute(routes.accountingDetailsDeprecated):
      return {
        isDeprecatedAccountingRoute: true,
        to: parameters.transactionId
          ? generateTo(routes.accountingDetailEdit)
          : generateTo(routes.accountingDetail),
      }
    default:
      return {
        isDeprecatedAccountingRoute: false,
        to: undefined,
      }
  }
}
