import * as React from "react"
import { Route, Switch } from "react-router-dom"
import { SpringboardModuleLoading } from "@digits-shared/components/Loaders"
import { ProtectedRoute } from "@digits-shared/components/Router/ProtectedRoute"
import { OSChrome } from "src/frontend/components/OS/OSChrome"
import { PublicChrome } from "src/frontend/components/Public/Chrome"
import { ChromeAlertContextProvider } from "src/frontend/components/Shared/Alerts/ChromeAlertsContext"
import { useObjectSharingState } from "src/frontend/hooks/useObjectSharingState"
import routes from "src/frontend/routes"
import { ObjectSharingStatus } from "src/frontend/session/ObjectSharingStatus"

/*
  ON-DEMAND COMPONENTS
*/

const Onboard = React.lazy(() => import("src/frontend/components/Onboard/Onboard"))

const PlgOnboardingApp = React.lazy(
  () => import("src/frontend/components/Onboard/PLG/PlgOnboardApp")
)

const SessionModule = React.lazy(() => import("src/frontend/components/Session"))

const Legal = React.lazy(() => import("src/frontend/components/Legal"))

const PayoutApplication = React.lazy(
  () => import("src/frontend/components/OS/Springboard/Applications/Payout/PayoutApplication")
)

/*
  COMPONENTS
*/

export const SharedObjectClientRoutes: React.FC = () => {
  const { previewError, previewLoading, objectSharingStatus } = useObjectSharingState()

  // If the object preview check returns an error (not found means not shared),
  // switch back to the normal route rendering path.
  if (previewError && objectSharingStatus !== ObjectSharingStatus.IntentRevoked)
    return <ClientRoutes />

  // Otherwise, display a loading spinner while we check on the shared object status,
  // or present the public routes for handling shared objects.
  return <PublicChrome loading={previewLoading} />
}

export const ClientRoutes: React.FC = () => (
  <ChromeAlertContextProvider>
    <React.Suspense fallback={<SpringboardModuleLoading />}>
      <Switch>
        <Route path={routes.legal.parameterizedPath} component={Legal} />

        {/* Sign in & Sign out */}
        <Route exact path={routes.login.parameterizedPath} component={SessionModule} />
        <Route
          exact
          path={[routes.singleSignOn.parameterizedPath, routes.signupCommunity.parameterizedPath]}
          component={SessionModule}
        />
        <Route exact path={routes.logout.parameterizedPath} component={SessionModule} />
        <Route path={routes.passwordReset.parameterizedPath} exact component={SessionModule} />

        {/* PLG Signup */}
        <Route path={routes.plg.parameterizedPath} component={PlgOnboardingApp} />

        {/* Signup */}
        <Route
          path={[
            routes.welcome.parameterizedPath,
            routes.legacySignup.parameterizedPath,
            routes.onboard.parameterizedPath,
          ]}
          component={Onboard}
        />

        {/* Collect Funds */}
        <Route exact path={routes.collectFunds.parameterizedPath} component={PayoutApplication} />

        <Route
          path="*"
          render={() => (
            <ProtectedRoute component={OSChrome} redirectTo={routes.login.parameterizedPath} />
          )}
        />
      </Switch>
    </React.Suspense>
  </ChromeAlertContextProvider>
)
